import { useState } from 'react';
import { isAxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useNotification } from '~/Shared/hooks/useNotification';
import { recoveryPassword } from '~/Shared/domain/user/api/auth/recoveryPassword';

export const useRecoveryPassword = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation('notification');
  const [resendEmail, setResendEmail] = useState(false);

  const { mutate, isLoading } = useMutation(recoveryPassword, {
    onSuccess: () => {
      if (resendEmail) showNotification('success', t('resetPasswordSuccess'));
      setResendEmail(true);
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showNotification('error', error?.response?.data.message);
      }
    },
  });

  return {
    mutate,
    isLoading,
    resendEmail,
  };
};

import { Box, Dialog, IconButton, Slide, Typography } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import {
  forwardRef,
  type FC,
  useState,
  type ReactElement,
  type Ref,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as styles from './dialogTutorial.styles';

import { OpenTutorial } from '~/Employer/domain/tutorial/components/OpenTutorial/OpenTutorial';
import { Task } from '~/Employer/domain/tutorial/components/Task/Task';
import { useFeatureFlags } from '~/Shared/config/featureFlags/hooks';
import { useTutorial } from '~/Employer/domain/tutorial/hooks/useTutorial';
import { EEmployerRoutes } from '~/Employer/pages';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogTutorial: FC = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const { isStepDone, isAllDone } = useTutorial();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { t } = useTranslation(['tutorial']);

  const handleClickTutorial = () => {
    setOpen((prev) => !prev);
  };

  if (!isFeatureEnabled('tutorialMode') || isAllDone) return null;

  const steps = [
    {
      id: 1,
      title: t('tutorial:companyInformationRequired'),
      onClick: () => navigate(EEmployerRoutes.SETTING_ORGANIZATION),
      buttonTitle: t('tutorial:upload'),
      done: isStepDone('companyInfo'),
    },
    {
      id: 2,
      title: t('tutorial:generalInformationRequired'),
      onClick: () => navigate(EEmployerRoutes.SETTINGS_PERSONAL),
      buttonTitle: t('tutorial:upload'),
      done: isStepDone('userInfo'),
    },
    // {
    //   id: 3,
    //   title: t('tutorial:inviteTeamManager'),
    //   onClick: () => navigate('/employer/company-information'),
    //   buttonTitle: t('tutorial:invite'),
    // },
    // {
    //   id: 4,
    //   title: t('tutorial:createANewTeam'),
    //   onClick: () => navigate('/employer/company-information'),
    //   buttonTitle: t('tutorial:create'),
    // },
    {
      id: 5,
      title: t('tutorial:createANewContract'),
      onClick: () => navigate(EEmployerRoutes.CONTRACT_NEW),
      buttonTitle: t('tutorial:create'),
      done: isStepDone('createContract'),
    },
  ];

  return (
    <>
      <OpenTutorial onClick={handleClickTutorial} />
      <Dialog
        sx={styles.dialog}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickTutorial}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box>
          <Box sx={styles.wrap}>
            <Box sx={styles.description}>
              <Typography
                variant="lg"
                color={({ palette }) => palette.neutral[100]}
              >
                {t('tutorial:welcomeToInfinity')}
              </Typography>
              <Typography>{t('tutorial:completeTheFollowingTasks')}</Typography>
            </Box>
            <Box>
              <IconButton onClick={handleClickTutorial}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box mt={2}>
            {steps.map((step) => (
              <Box key={step.id} mb={1}>
                <Task
                  title={step.title}
                  onClick={step.onClick}
                  buttonTitle={step.buttonTitle}
                  isDone={step.done}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

import { useQuery } from '@tanstack/react-query';

import { getFeatureFlags, type TFeaturesKeys } from './api';

export const useFeatureFlags = () => {
  const { data, isLoading } = useQuery(['feature-flags'], getFeatureFlags, {
    staleTime: 10000000,
    cacheTime: 60000000,
  });

  const isFeatureEnabled = (feature: TFeaturesKeys) => {
    return data?.[feature]?.enabled;
  };

  return {
    featureFlags: data,
    isLoading,
    isFeatureEnabled,
  };
};

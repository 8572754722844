import { object, string, type TypeOf } from 'zod';

import { i18trans } from '~/translation/i18n';

const birthSchema = object({
  date: string(),
  place: string(),
});

const mobilePhoneSchema = object({
  number: string().min(1, i18trans.t('mobilePhoneRequired', { ns: 'user' })),
  area: string(),
  code: string().min(1, i18trans.t('areaCodeRequired', { ns: 'user' })),
});

const homePhoneSchema = object({
  number: string(),
  area: string(),
  code: string(),
});

export const personalSchema = object({
  personal: object({
    country: string().min(1, i18trans.t('nationalityRequired', { ns: 'user' })),
    workCountry: string().min(
      1,
      i18trans.t('workCountryRequired', { ns: 'user' })
    ),
    birth: birthSchema,
    mobilePhone: mobilePhoneSchema,
    homePhone: homePhoneSchema,
    passport: string().min(
      1,
      i18trans.t('passportNumberRequired', { ns: 'user' })
    ),
    gender: string(),
    nickname: string(),
  }),
});

export type TPersonal = TypeOf<typeof personalSchema>;

export const personalInit: TPersonal = {
  personal: {
    country: '',
    workCountry: '',
    birth: {
      date: '',
      place: '',
    },
    mobilePhone: {
      number: '',
      area: '',
      code: '',
    },
    homePhone: {
      number: '',
      area: '',
      code: '',
    },
    passport: '',
    gender: '',
    nickname: '',
  },
};

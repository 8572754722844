import { type TStyles } from '~/Shared/types/TStyles';

export const inputStyles: TStyles = {
  mt: 0,
};

export const passwordInformationStyles: TStyles = {
  color: ({ palette }) => palette.common.black,
  fontWeight: 600,
  mt: 0.5,
  mb: 1,
};

export const criteriaItemStyles: TStyles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
};

export const checkCircleStyles = ({ value }: { value: boolean }): TStyles => {
  return {
    fill: ({ palette }) => (value ? palette.green[70] : palette.red[50]),
    mr: 1,
    fontSize: '20px',
    mb: 0.25,
  };
};

import { useMediaQuery as useMediaQueryMui } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface IQuery {
  md: string;
  bg: string;
  lg: string;
  isBigScreen: boolean;
  isMobile: boolean;
}

export const useMediaQuery = (): IQuery => {
  const theme = useTheme();
  const md = theme.breakpoints.down('md');
  const bg = theme.breakpoints.between('sm', 'bg');
  const lg = theme.breakpoints.up('lg');
  const isBigScreen = useMediaQueryMui(theme.breakpoints.up('md'));
  const isMobile = useMediaQueryMui(theme.breakpoints.down('sm'));

  return { md, bg, lg, isBigScreen, isMobile };
};

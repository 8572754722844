import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { LoginPage } from '~/Shared/pages/LoginPage';
import { RegisterPage } from '~/Shared/pages/RegisterPage';
import { VerifyAccountPage } from '~/Shared/pages/VerifyAccountPage';
import { RegisterSuccessPage } from '~/Shared/pages/RegisterSuccessPage';
import { PasswordRecoveryPage } from '~/Shared/pages/PasswordRecoveryPage';
import { PasswordResetPage } from '~/Shared/pages/PasswordResetPage';
import { useWhoAmI } from '~/Shared/domain/user/hooks/useWhoAmI';
import { Organization as RegisterEmployerOrganization } from '~/Employer/domain/user/components/Register/steps/Organization';
import { Register as EmployeeRegisterDetails } from '~/Employee/domain/user/components/Register';
import { AccessProvider } from '~/Shared/utils/providers/AccessProvider';
import { PageForTests } from '~/Shared/pages/PageForTests';

export const SharedOutlet = (): React.ReactElement => {
  const { data, isError } = useWhoAmI();

  if (data?.type === 'employee' && !isError) {
    return <Navigate to="/employee" />;
  }
  if (data?.type === 'employer' && !isError) {
    return <Navigate to="/employer" />;
  }
  return <Outlet />;
};

export const sharedRoutes = {
  path: '',
  element: <SharedOutlet />,
  children: [
    { index: true, element: <Navigate to="/login" /> },
    { path: 'login', element: <LoginPage /> },
    { path: 'register', element: <RegisterPage /> },
    { path: 'register/success', element: <RegisterSuccessPage /> },
    {
      path: 'register/employee/details',
      element: <EmployeeRegisterDetails />,
    },
    {
      path: 'register/employer/organization',
      element: <RegisterEmployerOrganization />,
    },
    { path: 'auth/verify/:code', element: <VerifyAccountPage /> },
    {
      path: 'reset-password',
      children: [
        { index: true, element: <PasswordRecoveryPage /> },
        { path: 'confirm/:code', element: <PasswordResetPage /> },
      ],
    },
    {
      path: 'test-page',
      element: (
        <AccessProvider featureKey="testPage">
          <PageForTests />
        </AccessProvider>
      ),
    },
    { path: '*', element: <Navigate to="/login" replace /> },
  ],
};

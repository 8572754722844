import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';

import {
  wrapperStyles,
  buttonResendStyles,
  buttonWrapStyles,
  boxTypoStyles,
  h1Styles,
  paragraphStyles,
} from './index.styles';

import { Checkmark } from '~/Shared/components/Checkmark';
import { useResendCode } from '~/Shared/domain/user/hooks/useResendCode';

export const RegisterSuccess: React.FC = () => {
  const { t } = useTranslation(['common', 'user']);
  const { state: emailAddress } = useLocation();
  const navigate = useNavigate();
  const { mutate } = useResendCode();

  const checkIfEmailExists = () => {
    if (!emailAddress) {
      navigate('/register');
    }
  };

  useEffect(() => checkIfEmailExists(), []);

  return (
    <Box sx={wrapperStyles}>
      <Checkmark />
      <Box sx={boxTypoStyles}>
        <Typography component="h1" sx={h1Styles}>
          {t('user:congratulations')}
        </Typography>
        <Typography component="p" sx={paragraphStyles}>
          {t('user:registerSuccessInformation', { email: emailAddress })}
        </Typography>
      </Box>

      <Box sx={buttonWrapStyles}>
        <Button
          sx={buttonResendStyles}
          variant="outlined"
          onClick={() => mutate(emailAddress)}
        >
          {t('resend')}
        </Button>
      </Box>
    </Box>
  );
};

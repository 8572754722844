import { useState } from 'react';

export const useMultiStepForm = (initialValue = 0) => {
  const [currentStep, setCurrentStep] = useState<number>(initialValue);

  const next = () => {
    setCurrentStep((i) => {
      return i + 1;
    });
  };

  const back = () => {
    setCurrentStep((i) => {
      if (i <= 0) return i;
      return i - 1;
    });
  };

  const setStep = (step: number) => {
    setCurrentStep(step < 0 ? 0 : step);
  };

  const reset = () => {
    setCurrentStep(0);
  };

  return {
    currentStep,
    next,
    back,
    setStep,
    reset,
  };
};

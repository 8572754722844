import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import type { SubmitHandler } from 'react-hook-form';

import { boxStyles, typographyStyles } from './index.styles';

import { RegisterCredentialsForm } from '~/Shared/domain/user/components/RegisterCredentialsForm';
import { type TRegisterCredentials } from '~/Shared/domain/user/components/RegisterCredentialsForm/schema';

export const Credentials = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'user']);

  const onSubmit: SubmitHandler<TRegisterCredentials> = async (data) => {
    navigate('/register/employee/details', { state: data });
  };

  return (
    <Box sx={boxStyles}>
      <Typography variant="md" sx={typographyStyles}>
        {t('user:signUpEmployee')}
      </Typography>
      <RegisterCredentialsForm onSubmit={onSubmit} />
    </Box>
  );
};

import { type IWhoAmIEmployeeDTO } from '~/Shared/domain/user/api/user/dto/whoAmIEmployeeDTO';

export const whoamiEmployeeModel = (data: IWhoAmIEmployeeDTO) => ({
  id: data.id,
  updatedAt: data.updatedAt,
  createdAt: data.createdAt,
  type: data.type,
  email: data.email,
  firstName: data.firstName,
  middleName: data.middleName || '',
  lastName: data.lastName,
  avatarPath: data.avatarPath,
  personal: {
    birth: {
      date: data.personal.birth.date || '',
      place: data.personal.birth.place || '',
    },
    country: data.personal.country,
    gender: data.personal.gender,
    homePhone: {
      area: data.personal.homePhone.area,
      code: data.personal.homePhone.code,
      number: data.personal.homePhone.number,
    },
    mobilePhone: {
      area: data.personal.mobilePhone.area,
      code: data.personal.mobilePhone.code,
      number: data.personal.mobilePhone.number,
    },
    nickname: data.personal.nickname,
    passport: data.personal.passport,
    workCountry: data.personal.workCountry || '',
  },
  bank: {
    accountName: data.bank.accountName,
    accountNumber: data.bank.accountNumber,
    branchName: data.bank.branchName,
    country: data.bank.country,
    emergencyContact: {
      person: data.bank.emergencyContact.person,
      phone: {
        area: data.bank.emergencyContact.phone.area,
        code: data.bank.emergencyContact.phone.code,
        number: data.bank.emergencyContact.phone.number,
      },
      relationshipType: data.bank.emergencyContact.relationshipType,
    },
    name: data.bank.name,
    swift: data.bank.swift,
  },
  dependent: {
    benefits: data.dependent?.benefits || '',
    company: data.dependent?.company || '',
    endDate: data.dependent?.endDate || '',
    jobDescription: data.dependent?.jobDescription || '',
    maritalStatus: data.dependent?.martialStatus || '',
    passport: data.dependent?.passport || '',
    salary: data.dependent?.salary || '',
    spouseName: data.dependent?.spouseName || '',
    startDate: data.dependent?.startDate || '',
  },
});

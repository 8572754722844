import { Box, Button, Typography, useTheme } from '@mui/material';

interface ItemInterface {
  text: string;
  icon: JSX.Element;
  isActive: boolean;
}

export const Item = ({ text, icon, isActive }: ItemInterface) => {
  const theme = useTheme();

  return (
    <Button
      fullWidth
      sx={{
        justifyContent: 'flex-start',
        px: 1,
        my: 0.32,
        width: '100%',
        background: isActive
          ? theme.palette.green[10]
          : theme.palette.neutral[10],
      }}
      variant="text"
      startIcon={
        <Box
          sx={{
            ml: 1,
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            color: isActive
              ? theme.palette.green[30]
              : theme.palette.neutral[80],
          }}
        >
          {icon}
        </Box>
      }
    >
      <Typography
        sx={{
          textTransform: 'none',
          letterSpacing: 0,
          fontWeight: theme.typography.fontWeightRegular,
          mr: 1,
          color: isActive ? theme.palette.green[30] : theme.palette.neutral[80],
        }}
        variant="base"
      >
        {text}
      </Typography>
    </Button>
  );
};

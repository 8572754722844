import { type IWhoAmIEmployerDTO } from '~/Shared/domain/user/api/user/dto/whoAmIEmployerDTO';

export const whoamiEmployerModel = (data: IWhoAmIEmployerDTO) => ({
  id: data.id,
  updatedAt: data.updatedAt,
  createdAt: data.createdAt,
  type: data.type,
  organizationID: data.organizationID,
  email: data.email,
  firstName: data.firstName,
  middleName: data.middleName,
  lastName: data.lastName,
  avatarPath: data.avatarPath,
});

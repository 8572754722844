import { type TStyles } from '~/Shared/types/TStyles';

export const buttonStyles: TStyles = {
  textTransform: 'none',
  fontWeight: ({ typography }) => typography.fontWeightRegular,
  letterSpacing: 1,
};

export const titleStyles: TStyles = {
  fontWeight: ({ typography }) => typography.fontWeightBold,
  color: ({ palette }) => palette.common.black,
};

export const subtitleStyles: TStyles = { marginTop: 1 };

export const typoWrapStyles: TStyles = {
  display: 'flex',
  flexDirection: 'column',
};

export const stackStyles: TStyles = {
  mt: '10px',
  mb: '10px',
};

export const singInWrapStyles: TStyles = {
  mt: '16px',
};

export const linkStyles: TStyles = {
  marginLeft: 1,
  fontFamily: 'Poppins, sans-serif',
};

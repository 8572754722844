import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  Typography,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';

interface HeaderInterface {
  image: string;
  name: string;
  mobileHeight: string;
  desktopHeight: string;
  handleDrawerToggle: () => void;
  logout: () => void;
}

export const Header = ({
  mobileHeight,
  desktopHeight,
  name,
  image,
  logout,
  handleDrawerToggle,
}: HeaderInterface) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          position: 'fixed',
          zIndex: 10,
          top: 0,
          right: 0,
          height: {
            xs: mobileHeight,
            md: desktopHeight,
          },
          background: theme.palette.common.white,
          borderBottom: `1px solid ${theme.palette.neutral[40]}`,
          px: {
            xs: 2.5,
            md: 8,
          },
          display: 'flex',
          flexDirection: {
            xs: 'row',
            md: 'row-reverse',
          },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          color={'primary'}
          sx={{
            w: 2,
            h: 2,
            display: { xs: 'flex', md: 'none' },
            background: theme.palette.green[10],
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ width: 32, height: 32 }} alt={name} src={image} />
          <Typography
            sx={{ ml: 1.3, mr: 1.3 }}
            variant="base"
            color={theme.palette.common.black}
          >
            {name}
          </Typography>
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            data-testid="show-more"
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Button
          onClick={logout}
          fullWidth={true}
          data-testid="button-logout"
          sx={{
            justifyContent: 'flex-start',
            px: 4,
            my: 0.32,
            width: '100%',
            color: theme.palette.red[40],
          }}
          variant="text"
          startIcon={<LogoutIcon color="error" />}
        >
          <Typography
            sx={{
              textTransform: 'none',
              letterSpacing: 0,
              fontWeight: 400,
              mr: 1,
              color: theme.palette.red[40],
            }}
            variant="base"
          >
            Logout
          </Typography>
        </Button>
      </Menu>
    </>
  );
};

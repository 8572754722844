import React from 'react';
import { Box, useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const Checkmark: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: { xs: 80, sm: 150 },
        height: { xs: 80, sm: 150 },
        borderRadius: '50%',
        background: theme.palette.green['40'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CheckIcon
        sx={{
          fill: theme.palette.neutral['10'],
          fontSize: { xs: '50px', sm: '70px' },
        }}
      />
    </Box>
  );
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import { type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { typography } from './index.styles';

import { RegisterCredentialsForm } from '~/Shared/domain/user/components/RegisterCredentialsForm';
import { type TRegisterCredentials } from '~/Shared/domain/user/components/RegisterCredentialsForm/schema';

export const Credentials: React.FC = () => {
  const { t } = useTranslation(['common', 'user']);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<TRegisterCredentials> = async (data) => {
    navigate('/register/employer/organization', { state: data });
  };

  return (
    <Box mt="10px">
      <Typography variant="md" sx={typography}>
        {t('user:signUpEmployer')}
      </Typography>
      <RegisterCredentialsForm onSubmit={onSubmit} />
    </Box>
  );
};

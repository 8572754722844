import type { TStyles } from '~/Shared/types/TStyles';

export const paperStyles: TStyles = {
  m: '16px',
  minHeight: '940px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export const stackStyles: TStyles = {
  gap: 2.5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  p: '24px',
  w: '100%',
  maxWidth: '560px',
};

export const iconWrapperCommonStyles: TStyles = ({ palette }) => ({
  background: palette.yellow[40],
  width: '150px',
  height: '150px',
  borderRadius: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const iconWrapperCriticalStyles: TStyles = ({ palette }) => ({
  background: palette.red[40],
  width: '150px',
  height: '150px',
  borderRadius: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

export const iconStyles: TStyles = ({ palette }) => ({
  width: '75px',
  height: '75px',
  fill: palette.neutral[10],
});

export const titleStyles: TStyles = ({ palette }) => ({
  color: palette.green[40],
  textAlign: 'center',
  fontWeight: 700,
});

export const paragraphStyles: TStyles = ({ palette }) => ({
  color: palette.neutral[60],
  textAlign: 'center',
  fontWeight: 100,
});

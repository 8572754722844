import { Box } from '@mui/material';

import { DesktopVariant } from './Drawer/DesktopVariant';
import { MobileVariant } from './Drawer/MobileVariant';
import { Header } from './Header';
import { Outlet } from './Outlet';

import { useLogout } from '~/Shared/domain/user/hooks/useLogout';
import { useBoolean } from '~/Shared/hooks/useBoolean';
import { constants } from '~/Shared/utils/layoutConstants';
import { useActiveWhoAmI } from '~/Employer/domain/user/hooks/useActiveWhoAmI';
import { DialogTutorial } from '~/Employer/domain/tutorial/components/DialogTutorial/DialogTutorial';

export const Layout = () => {
  const { firstName, lastName, avatarPath } = useActiveWhoAmI();
  const { value: drawerStatus, toggle: toggleDrawer } = useBoolean(false);

  const mutation = useLogout();

  const handleLogout = () => {
    mutation.mutate();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'row' }}>
      <DialogTutorial />
      <Box sx={{ width: { md: constants.drawerWidth }, flexShrink: { md: 0 } }}>
        <MobileVariant
          topOffset={constants.mobileHeaderHeight}
          width={constants.drawerWidth}
          open={drawerStatus}
          handleToggle={toggleDrawer}
        />
        <DesktopVariant width={constants.drawerWidth} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: {
            md: `calc(100% - ${constants.drawerWidth}px)`,
          },
        }}
      >
        <Header
          image={avatarPath}
          name={`${firstName} ${lastName}`}
          mobileHeight={constants.mobileHeaderHeight}
          desktopHeight={constants.desktopHeaderHeight}
          handleDrawerToggle={toggleDrawer}
          logout={handleLogout}
        />
        <Outlet
          mobileHeight={constants.mobileHeaderHeight}
          desktopHeight={constants.desktopHeaderHeight}
        />
      </Box>
    </Box>
  );
};

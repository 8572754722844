import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { EmployeeOutlet } from '~/Employee/EmployeeOutlet';
import { Layout } from '~/Employee/Layout';
import { lazyImport } from '~/Shared/utils/lazyImport';
import { AccessProvider } from '~/Shared/utils/providers/AccessProvider';

const { Settings } = lazyImport(
  () => import('~/Employee/pages/settings/Settings'),
  'Settings'
);

const { BankInformationPage } = lazyImport(
  () => import('~/Employee/pages/settings/BankInformation'),
  'BankInformationPage'
);

const { PersonalInformationPage } = lazyImport(
  () => import('~/Employee/pages/settings/PersonalInformation'),
  'PersonalInformationPage'
);

const { ContractList } = lazyImport(
  () => import('~/Employee/pages/ContractList'),
  'ContractList'
);
const { ContractDetail } = lazyImport(
  () => import('~/Employee/pages/ContractDetail'),
  'ContractDetail'
);
const { ReceiptsList } = lazyImport(
  () => import('~/Employee/pages/ReceiptsList'),
  'ReceiptsList'
);
const { ReceiptDetails } = lazyImport(
  () => import('~/Employee/pages/ReceiptDetails'),
  'ReceiptDetails'
);
const { ComplianceDocument } = lazyImport(
  () => import('~/Employee/pages/ComplianceDocument'),
  'ComplianceDocument'
);

const { VisaApplicationStatus } = lazyImport(
  () =>
    import(
      '~/Employee/pages/VisaService/VisaApplicationStatus/VisaApplicationStatus'
    ),
  'VisaApplicationStatus'
);
const { VisaDetails } = lazyImport(
  () => import('~/Employee/pages/VisaService/VisaDetails/VisaDetails'),
  'VisaDetails'
);

export enum EEmployeeRoutes {
  EMPLOYEE = '/employee',
  CONTRACT_LIST = '/employee/contract/list',
  CONTRACT_DETAIL = '/employee/contract/list/:id',
  CONTRACT_COMPLIANCE = '/employee/contract/compliance',
  RECEIPTS_LIST = '/employee/receipts/list',
  RECEIPT_DETAIL = '/employee/receipts/list/:id',
  VISA_APPLICATION_STATUS = '/employee/visa-service/list',
  VISA_DETAILS = '/employee/visa-service/:id',
  SETTINGS = '/employee/settings',
  SETTINGS_PERSONAL = '/employee/settings/personal-information',
  SETTINGS_BANK = '/employee/settings/bank-information',
}
export const employeeRoutes = {
  path: EEmployeeRoutes.EMPLOYEE,
  element: (
    <Suspense fallback="loading">
      <EmployeeOutlet />
    </Suspense>
  ),
  children: [
    {
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Navigate to={EEmployeeRoutes.CONTRACT_LIST} replace />,
        },
        {
          children: [
            // CONTRACTS
            {
              path: EEmployeeRoutes.CONTRACT_LIST,
              element: (
                <AccessProvider>
                  <ContractList />
                </AccessProvider>
              ),
            },
            {
              path: EEmployeeRoutes.CONTRACT_DETAIL,
              element: (
                <AccessProvider>
                  <ContractDetail />
                </AccessProvider>
              ),
            },
            {
              path: EEmployeeRoutes.CONTRACT_COMPLIANCE,
              element: (
                <AccessProvider>
                  <ComplianceDocument />
                </AccessProvider>
              ),
            },
            // RECEIPTS
            {
              path: EEmployeeRoutes.RECEIPTS_LIST,
              element: (
                <AccessProvider>
                  <ReceiptsList />
                </AccessProvider>
              ),
            },
            {
              path: EEmployeeRoutes.RECEIPT_DETAIL,
              element: (
                <AccessProvider>
                  <ReceiptDetails />
                </AccessProvider>
              ),
            },
            // VISA APPLICATION STATUS
            {
              path: EEmployeeRoutes.VISA_APPLICATION_STATUS,
              element: (
                <AccessProvider featureKey="visaService">
                  <VisaApplicationStatus />
                </AccessProvider>
              ),
            },
            {
              path: EEmployeeRoutes.VISA_DETAILS,
              element: (
                <AccessProvider featureKey="visaService">
                  <VisaDetails />
                </AccessProvider>
              ),
            },
            // SETTINGS
            {
              path: EEmployeeRoutes.SETTINGS,
              element: <Settings />,
              children: [
                {
                  index: true,
                  element: <Navigate to={EEmployeeRoutes.SETTINGS_PERSONAL} />,
                },
                {
                  path: EEmployeeRoutes.SETTINGS_PERSONAL,
                  element: (
                    <AccessProvider>
                      <PersonalInformationPage />
                    </AccessProvider>
                  ),
                },
                {
                  path: EEmployeeRoutes.SETTINGS_BANK,
                  element: (
                    <AccessProvider>
                      <BankInformationPage />
                    </AccessProvider>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

import React, { Suspense } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Box, CircularProgress } from '@mui/material';

import { CriticalError } from '~/Shared/components/ErrorBoundary/CriticalError';
import { fallbackBoxFlexStyles } from '~/Shared/utils/providers/SuspenseProvider/index.styles';

interface IProps {
  children: React.ReactNode;
}

export const SuspenseProvider = ({ children }: IProps) => {
  return (
    <ErrorBoundary fallback={<CriticalError />}>
      <Suspense
        fallback={
          <Box sx={fallbackBoxFlexStyles}>
            <CircularProgress />
          </Box>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

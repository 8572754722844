import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { Menu } from './Menu';
import { Item } from './Item';

import logo from '~/Shared/assets/logo-remote.svg';
import { useMenu } from '~/Employee/Layout/hooks/useMenu';

export const Content = () => {
  const { menu } = useMenu();
  return (
    <Box>
      <Box
        component="img"
        sx={{ maxWidth: '100%', mt: 3.75, mb: 3.75, px: 4 }}
        src={logo}
      />
      {menu.map((el, index) => {
        if (el.hidden) {
          return null;
        }
        return (
          <Menu key={index} {...el}>
            {el.links.map((link) => (
              <NavLink to={link.url} key={link.url}>
                {({ isActive }) => <Item isActive={isActive} {...link} />}
              </NavLink>
            ))}
          </Menu>
        );
      })}
    </Box>
  );
};

import { useQuery } from '@tanstack/react-query';

import { getWhoAmI } from '~/Shared/domain/user/api/user';

export const useWhoAmI = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['whoami'],
    retry: 0,
    queryFn: getWhoAmI,
  });

  return {
    data,
    isLoading,
    isError,
  };
};

import axios from 'axios';

type TProps = {
  password: string;
  code: string | undefined;
};

export const resetPassword = async (dataResetPassword: TProps) => {
  const { code, password } = dataResetPassword;
  const res = await axios.post(`/auth/user/reset-password/confirm/${code}`, {
    code,
    password,
  });

  return res;
};

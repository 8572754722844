import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { object, string, type TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import { i18trans } from '~/translation/i18n';
import { useMediaQuery } from '~/Shared/hooks/useMediaQuery';
import { useRecoveryPassword } from '~/Shared/domain/user/hooks/useRecoveryPassword';
import { TextField } from '~/Shared/components/Forms/ControlledInputs/TextField';
import { SplitOverlay } from '~/Shared/components/SplitOverlay';
import Background from '~/Shared/assets/reset-illustration-big.png';
import { useCountDown } from '~/Shared/hooks/useCountDown';

const passwordRecoverySchema = object({
  email: string({
    required_error: i18trans.t('emailRequired', { ns: 'user' }),
  }).email(i18trans.t('emailIsInvalid', { ns: 'user' })),
});

type TPasswordRecovery = TypeOf<typeof passwordRecoverySchema>;

export const PasswordRecoveryPage: React.FC = () => {
  const { timeLeft, startCountDown } = useCountDown(30);
  const { t } = useTranslation('user');
  const theme = useTheme();
  const { isBigScreen, md } = useMediaQuery();
  const navigate = useNavigate();
  const { mutate, resendEmail } = useRecoveryPassword();

  const { handleSubmit, control } = useForm<TPasswordRecovery>({
    resolver: zodResolver(passwordRecoverySchema),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<TPasswordRecovery> = (data) => {
    mutate(data.email);
    startCountDown();
  };

  return (
    <SplitOverlay background={Background}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        {resendEmail ? (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant={isBigScreen ? 'xxxl' : 'lg'}
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.common.black,
                }}
              >
                {t('checkYourInbox')}
              </Typography>
              <Typography
                variant={isBigScreen ? 'base' : 'xs'}
                sx={{ marginTop: 1 }}
              >
                {t('inboxInformation')}
              </Typography>
              {Boolean(timeLeft) && (
                <>
                  <Alert
                    severity="warning"
                    sx={{
                      fontWeight: theme.typography.fontWeightBold,
                      mt: '8px',
                      mb: '8px',
                    }}
                  >
                    {t('resendInformation', { time: timeLeft })}
                  </Alert>
                </>
              )}
            </Box>
            <Stack
              spacing={2}
              sx={{
                mt: '30px',
                mb: '10px',
                [md]: {
                  mt: '10px',
                },
              }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={timeLeft !== 0}
              >
                {t('resetPassword')}
              </Button>
              <Button onClick={() => navigate('/login')} variant="outlined">
                {t('backToLogin')}
              </Button>
            </Stack>
          </Box>
        ) : (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant={isBigScreen ? 'xxxl' : 'lg'}
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                  color: theme.palette.common.black,
                }}
              >
                {t('resetPassword')}
              </Typography>
              <Typography
                variant={isBigScreen ? 'base' : 'xs'}
                sx={{ marginTop: 1 }}
              >
                {t('resetPasswordSubtitle')}
              </Typography>
            </Box>
            <Stack
              spacing={2}
              sx={{
                mt: '30px',
                mb: '10px',
                [md]: {
                  mt: '10px',
                },
              }}
            >
              <TextField<TPasswordRecovery>
                name="email"
                type="email"
                control={control}
                placeholder={t('placeholderEmail')}
                sx={{ mt: '2px', mb: 0 }}
                startAdornment={
                  <InputAdornment position="start">
                    <MailOutlineIcon />
                  </InputAdornment>
                }
              />
              <Button variant="contained" type="submit">
                {t('resetPassword')}
              </Button>
              <Button onClick={() => navigate('/login')} variant="outlined">
                {t('backToLogin')}
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </SplitOverlay>
  );
};

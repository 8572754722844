import React from 'react';
import { Typography, useTheme } from '@mui/material';
import type { SxProps } from '@mui/material';

interface ILabel {
  text: string | undefined;
  sx?: SxProps;
}

export const Label: React.FC<ILabel> = ({ text, sx }) => {
  const theme = useTheme();

  return text ? (
    <Typography
      component="label"
      sx={{
        display: 'block',
        color: theme.palette.neutral[100],
        fontSize: theme.typography.base.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        ...sx,
      }}
    >
      {text}
    </Typography>
  ) : null;
};

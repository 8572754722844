import { ThemeProvider, type PaletteMode } from '@mui/material';
import React, { useMemo, useState } from 'react';

import { darkTheme } from './darkTheme';
import { lightTheme } from './lightTheme';

interface IThemeProvider {
  toggleColorMode: () => void;
}

const ThemeProviderContext = React.createContext<IThemeProvider | undefined>(
  undefined
);

export const ThemeProviderCustom: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode>('light');

  const setTheme = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === 'light' ? 'dark' : 'light'
        );
      },
    }),
    []
  );

  return (
    <ThemeProviderContext.Provider value={setTheme}>
      <ThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
        {children}
      </ThemeProvider>
    </ThemeProviderContext.Provider>
  );
};

export const useThemeProviderCustom = (): IThemeProvider => {
  const context = React.useContext(ThemeProviderContext);
  if (!context) {
    throw new Error('TeamContext is beyond TeamProvider');
  }
  return context;
};

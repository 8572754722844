import type { TStyles } from '~/Shared/types/TStyles';

export const dialog: TStyles = {
  left: 'initial',
  top: 'initial',
  '.MuiPaper-root': {
    padding: '35px 40px',
    width: '530px',
  },
};

export const wrap: TStyles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const description: TStyles = {
  width: '323px',
};

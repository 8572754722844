import React from 'react';
import {
  Box,
  TextField as MUITextField,
  type TextFieldProps,
} from '@mui/material';
import {
  Controller,
  type Control,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';

import { Label } from '~/Shared/components/Forms/Label';
import { Statement } from '~/Shared/components/Forms/Statement';
import { HelperText } from '~/Shared/components/Forms/HelperText';

type TTextField<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  type?: string;
  label?: string;
  alert?: string;
  helperText?: string;
  statement?: string;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  readOnly?: boolean;
  displayErrorMessage?: boolean;
} & TextFieldProps;

export const TextField = <T extends FieldValues>({
  name,
  control,
  type,
  label,
  statement,
  helperText,
  placeholder,
  startAdornment,
  endAdornment,
  readOnly,
  displayErrorMessage = true,
  ...rest
}: TTextField<T>) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Label text={label} />
      <Statement text={statement} />
      <HelperText text={helperText} />
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange }, fieldState }) => (
          <MUITextField
            value={value || ''}
            onChange={onChange}
            type={type || 'text'}
            error={!!fieldState.error}
            helperText={
              (displayErrorMessage && fieldState?.error?.message) || ''
            }
            autoComplete="off"
            InputProps={{
              placeholder: placeholder,
              startAdornment: startAdornment,
              endAdornment: endAdornment,
              sx: rest?.sx || { mb: '20px', mt: '10px' },
              readOnly: readOnly,
            }}
            FormHelperTextProps={{
              role: 'alert',
            }}
            {...rest}
          />
        )}
      />
    </Box>
  );
};

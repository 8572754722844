import React from 'react';
import { Drawer, useTheme } from '@mui/material';

import { Content } from './Content';

interface MobileVariantInterface {
  topOffset: string;
  width: string;
  open: boolean;
  handleToggle: () => void;
}

export const MobileVariant = ({
  topOffset,
  width,
  open,
  handleToggle,
}: MobileVariantInterface) => {
  const theme = useTheme();

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: width,
          height: `calc(100% - ${topOffset})`,
          marginTop: topOffset,
          boxSizing: 'border-box',
          boxShadow: 'none',
          borderBottom: `1px solid ${theme.palette.neutral[40]}`,
        },
      }}
      BackdropProps={{
        sx: {
          top: topOffset,
        },
      }}
      variant="temporary"
      open={open}
      onClose={handleToggle}
      ModalProps={{
        sx: {
          display: { xs: 'block', md: 'none' },
          top: topOffset,
        },
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
      }}
    >
      <Content />
    </Drawer>
  );
};

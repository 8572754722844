import { useQuery } from '@tanstack/react-query';

import { getCountries } from '../api';

export const useCountriesList = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['countries'],
    queryFn: () => getCountries(),
    staleTime: 36 * 100000,
    cacheTime: 36 * 100000,
  });

  const countriesData = data?.filter((country) => country.independent);

  const getAreaCode = (countryCode: string) => {
    return (
      countriesData?.find((country) => country.alpha2Code === countryCode)
        ?.callingCodes[0] || ''
    );
  };

  return {
    countries: countriesData,
    getAreaCode,
    isLoading,
    isError,
  };
};

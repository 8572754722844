import React from 'react';
import {
  Alert,
  type SxProps,
  type Theme,
  type AlertProps,
} from '@mui/material';

type TStatement = {
  text: string | undefined;
  sx?: SxProps<Theme>;
} & AlertProps;

export const Statement: React.FC<TStatement> = ({ text, sx, ...rest }) => {
  return text ? (
    <Alert severity="warning" sx={{ mb: '14px', mt: '5px', ...sx }} {...rest}>
      {text}
    </Alert>
  ) : null;
};

import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Stack, Link, Button } from '@mui/material';
import ReactGA from 'react-ga4';

import {
  titleStyles,
  buttonStyles,
  stackStyles,
  linkStyles,
  subtitleStyles,
  typoWrapStyles,
  singInWrapStyles,
} from './index.styles';

import { useMediaQuery } from '~/Shared/hooks/useMediaQuery';
import { SplitOverlay } from '~/Shared/components/SplitOverlay';
import IllustrationBig from '~/Shared/assets/register-illustration-big.png';
import { Credentials as RegisterCredentialsEmployer } from '~/Employer/domain/user/components/Register/steps/Credentials';
import { Credentials as RegisterCredentialsEmployee } from '~/Employee/domain/user/components/Register/steps/Credentials';

enum ECustomerType {
  Employee = 'employee',
  Employer = 'employer',
}

const componentCustomerType = {
  [ECustomerType.Employee]: <RegisterCredentialsEmployee />,
  [ECustomerType.Employer]: <RegisterCredentialsEmployer />,
};

export const RegisterPage: React.FC = () => {
  const [selectedCustomerType, setSelectedCustomerType] =
    useState<ECustomerType | null>(null);
  const { t } = useTranslation('user');
  const { isBigScreen } = useMediaQuery();

  useEffect(() => {
    if (import.meta.env.VITE_APP_GA) {
      ReactGA.initialize(import.meta.env.VITE_APP_GA);
      ReactGA.send({ hitType: 'pageview' });
    }
  }, []);

  return (
    <SplitOverlay background={IllustrationBig}>
      <Box>
        <Box sx={typoWrapStyles}>
          <Typography variant={isBigScreen ? 'xxxl' : 'lg'} sx={titleStyles}>
            {t('registerTitle')}
          </Typography>
          <Typography variant={isBigScreen ? 'base' : 'xs'} sx={subtitleStyles}>
            {t('registerSubtitle')}
          </Typography>
        </Box>

        <Stack
          direction={isBigScreen ? 'row' : 'column'}
          spacing={2}
          sx={stackStyles}
        >
          <Button
            variant={
              selectedCustomerType === ECustomerType.Employer
                ? 'contained'
                : 'outlined'
            }
            sx={buttonStyles}
            onClick={() => setSelectedCustomerType(ECustomerType.Employer)}
            fullWidth
            data-testid="btn-register-employer"
          >
            {t('registerBusiness')}
          </Button>
          <Button
            variant={
              selectedCustomerType === ECustomerType.Employee
                ? 'contained'
                : 'outlined'
            }
            fullWidth
            sx={buttonStyles}
            onClick={() => setSelectedCustomerType(ECustomerType.Employee)}
            data-testid="btn-register-employee"
          >
            {t('registerEmployee')}
          </Button>
        </Stack>
        <Box>
          {selectedCustomerType !== null &&
            componentCustomerType[selectedCustomerType]}
        </Box>
        <Box sx={singInWrapStyles}>
          <Typography variant={isBigScreen ? 'base' : 'xs'}>
            {t('haveAccount')}
          </Typography>
          <Link component={RouterLink} to="/login" sx={linkStyles}>
            {t('signIn')}
          </Link>
        </Box>
      </Box>
    </SplitOverlay>
  );
};

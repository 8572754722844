export const neutral = {
  10: '#FFFFFF',
  20: '#F6F6F7',
  30: '#EFEFF1',
  40: '#E4E4E6',
  50: '#919EAB',
  60: '#A8AAB1',
  70: '#848690',
  80: '#727480',
  90: '#545765',
  100: '#24283A',
};

export const green = {
  10: '#D4E9E2',
  20: '#8E9C99',
  30: '#435A54',
  40: '#1E3932',
  50: '#0F1C19',
  60: '#0A1311',
  70: '#54B878',
  80: '#19A147',
};

export const blue = {
  10: '#E6F8FF',
  20: '#CCEFFC',
  30: '#AAE4FA',
  40: '#01AFF1',
  50: '#0192C9',
  60: '#005779',
};

export const yellow = {
  10: '#FFF4D8',
  20: '#FFE49E',
  30: '#FEDB7E',
  40: '#FEC93D',
  50: '#A98629',
  60: '#554314',
  70: '#F1A041',
  80: '#FF8B00',
};

export const red = {
  10: '#FFEDEC',
  20: '#FFCAC9',
  30: '#FF9490',
  40: '#FF6762',
  50: '#FF4842',
  60: '#B72136',
};

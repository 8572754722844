import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { object, string, type TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, type SubmitHandler } from 'react-hook-form';

import { i18trans } from '~/translation/i18n';
import { useMediaQuery } from '~/Shared/hooks/useMediaQuery';
import { useLogin } from '~/Shared/domain/user/hooks/useLogin';
import { TextField } from '~/Shared/components/Forms/ControlledInputs/TextField';

const loginSchema = object({
  email: string({
    required_error: i18trans.t('emailRequired', { ns: 'user' }),
  }).email(i18trans.t('emailIsInvalid', { ns: 'user' })),
  password: string({
    required_error: i18trans.t('passwordRequired', { ns: 'user' }),
  }).min(8, i18trans.t('passwordTooShort', { ns: 'user' })),
});

export type TLoginInputs = TypeOf<typeof loginSchema>;

export const LoginForm: React.FC = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { t } = useTranslation('user');
  const { isBigScreen, md, lg } = useMediaQuery();
  const { mutate, isLoading, isError } = useLogin();

  const { handleSubmit, control, setError } = useForm<TLoginInputs>({
    resolver: zodResolver(loginSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    if (isError) {
      setError('email', { type: 'user' });
      setError(
        'password',
        { type: 'user', message: t('invalidEmailOrPassword') },
        { shouldFocus: true }
      );
    }
  }, [isError]);

  const togglePasswordVisibility = () => setIsPasswordVisible((prev) => !prev);

  const onSubmit: SubmitHandler<TLoginInputs> = (data) => {
    mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={2}
        sx={{
          mt: '30px',
          mb: '10px',
          [md]: {
            mt: '10px',
          },
        }}
      >
        <TextField<TLoginInputs>
          name="email"
          control={control}
          type="email"
          placeholder={t('email')}
          autoComplete="on"
          sx={{ mt: 0 }}
          inputProps={{ 'data-testid': 'input-login-email' }}
          startAdornment={
            <InputAdornment position="start">
              <EmailOutlinedIcon />
            </InputAdornment>
          }
        />

        <TextField<TLoginInputs>
          name="password"
          control={control}
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder={t('password')}
          autoComplete="on"
          sx={{ mt: 0 }}
          inputProps={{ 'data-testid': 'input-login-password' }}
          startAdornment={
            <InputAdornment position="start">
              <LockOutlinedIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {isPasswordVisible ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          }
        />

        <Typography
          variant={isBigScreen ? 'sm' : 'xs'}
          sx={(theme) => ({
            color: theme.palette.grey[600],
            [lg]: {
              maxWidth: '80%',
            },
          })}
        >
          {t('policyPrivacy')}
        </Typography>
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          data-testid="button-login"
        >
          {t('login')}
        </Button>
      </Stack>
    </form>
  );
};

import React from 'react';
import { useTheme, Typography } from '@mui/material';

interface ISelectPlaceholder {
  children: string;
}

export const SelectPlaceholder = ({ children }: ISelectPlaceholder) => {
  const theme = useTheme();

  return (
    <Typography
      variant="base"
      sx={{
        color: theme.palette.neutral[60],
      }}
    >
      {children}
    </Typography>
  );
};

import React, { useState, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useForm, type SubmitHandler } from 'react-hook-form';
import {
  Box,
  Button,
  FormGroup,
  MenuItem,
  Typography,
  Stack,
  InputAdornment,
} from '@mui/material';

import {
  textFieldStyles,
  typographyStyles,
  stackStyles,
  datePickerStyles,
  buttonBoxStyles,
  buttonSubmitStyles,
  buttonBackStyles,
  endAdornmentTypographyStyles,
} from './index.styles';

import {
  type TDependent,
  dependentSchema,
} from '~/Employee/domain/user/components/Register/steps/DependentDetails/schema';
import { TextField } from '~/Shared/components/Forms/ControlledInputs/TextField';
import { DatePicker } from '~/Shared/components/Forms/ControlledInputs/DatePicker';
import { Select } from '~/Shared/components/Forms/ControlledInputs/Select';

interface IProps {
  state: TDependent;
  setState: React.Dispatch<React.SetStateAction<TDependent>>;
  back: () => void;
  handleSubmitForm: (lastStepState: TDependent) => Promise<void>;
}

export const DependentDetails = ({
  state,
  setState,
  back,
  handleSubmitForm,
}: IProps) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { t } = useTranslation(['common', 'user']);

  const { control, handleSubmit, getValues, watch } = useForm<TDependent>({
    defaultValues: state,
    resolver: zodResolver(dependentSchema),
    mode: 'onBlur',
  });

  const startDate = watch('dependent.startDate');
  const endDate = watch('dependent.endDate');

  useEffect(() => {
    if (!startDate) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [startDate, endDate]);

  const martialStatuses = [
    'married',
    'single',
    'divorced',
    'widowed',
    'undisclosed',
  ] as const;

  const onSubmit: SubmitHandler<TDependent> = async (data) => {
    setState(data);

    await handleSubmitForm(data);
  };

  const onBack = () => {
    setState(getValues());
    back();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="xxl" sx={typographyStyles}>
        {t('dependentDetails')}
      </Typography>
      <Typography>{t('user:dependentDetailsSubtitle')}</Typography>

      <FormGroup>
        <Stack spacing={2} sx={stackStyles}>
          <Select<TDependent>
            name="dependent.maritalStatus"
            control={control}
            label={t('user:maritalStatusLabel')}
            placeholder={t('user:maritalStatusPlaceholder')}
            displayEmpty
            fullWidth
          >
            {martialStatuses?.map((status) => (
              <MenuItem key={status} value={status}>
                {t(status)}
              </MenuItem>
            ))}
          </Select>

          <TextField<TDependent>
            name="dependent.spouseName"
            placeholder={t('user:spouseNamePlaceholder')}
            label={t('user:spouseNameLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <TextField<TDependent>
            name="dependent.passport"
            placeholder={t('user:dependentPassportNumberPlaceholder')}
            label={t('user:dependentPassportNumberLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <TextField<TDependent>
            name="dependent.company"
            placeholder={t('user:endClientCompanyPlaceholder')}
            label={t('user:endClientCompanyLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <Stack direction="row" columnGap="20px">
            <Stack flex={1}>
              <DatePicker<TDependent>
                name="dependent.startDate"
                control={control}
                label={t('user:startDateLabel')}
                placeholder={t('datePlaceholder')}
                maxDate={endDate || ''}
                sx={datePickerStyles}
              />
            </Stack>

            <Stack flex={1}>
              <DatePicker<TDependent>
                name="dependent.endDate"
                control={control}
                label={t('user:endDateLabel')}
                placeholder={t('datePlaceholder')}
                disabled={isDisabled}
                minDate={startDate}
                sx={datePickerStyles}
              />
            </Stack>
          </Stack>

          <TextField<TDependent>
            name="dependent.salary"
            placeholder={t('user:monthlySalaryPlaceholder')}
            label={t('user:monthlySalaryLabel')}
            control={control}
            type="number"
            sx={textFieldStyles}
            endAdornment={
              <InputAdornment position="end">
                <Typography sx={endAdornmentTypographyStyles}>
                  {t('USD')}
                </Typography>
              </InputAdornment>
            }
            inputProps={{
              step: '0.01',
            }}
          />

          <TextField<TDependent>
            name="dependent.benefits"
            placeholder={t('user:otherBenefitsPlaceholder')}
            label={t('user:otherBenefitsLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <TextField<TDependent>
            name="dependent.jobDescription"
            placeholder={t('user:jobDescriptionPlaceholder')}
            label={t('user:jobDescriptionLabel')}
            control={control}
            multiline
            rows={4}
            sx={textFieldStyles}
          />
        </Stack>
      </FormGroup>
      <Box sx={buttonBoxStyles}>
        <Button sx={buttonBackStyles} variant="outlined" onClick={onBack}>
          {t('back')}
        </Button>
        <Button sx={buttonSubmitStyles} variant="contained" type="submit">
          {t('submitAndCreateAccount')}
        </Button>
      </Box>
    </Box>
  );
};

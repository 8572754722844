import React from 'react';

import { SuspenseProvider } from '~/Shared/utils/providers/SuspenseProvider';
import { FeatureProvider } from '~/Shared/utils/providers/FeatureProvider';
import type { TFeaturesKeys } from '~/Shared/config/featureFlags/api';

interface IProps {
  featureKey?: TFeaturesKeys;
  children: React.ReactNode;
}

export const AccessProvider = ({ children, featureKey }: IProps) => {
  return (
    <FeatureProvider flag={featureKey}>
      <SuspenseProvider>{children}</SuspenseProvider>
    </FeatureProvider>
  );
};

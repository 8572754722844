import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { useTranslation } from 'react-i18next';

import type { TFeaturesKeys } from '~/Shared/config/featureFlags/api';
import { useFeatureFlags } from '~/Shared/config/featureFlags/hooks';

interface Route {
  text: string;
  icon: JSX.Element;
  url: string;
  featureFlagName?: TFeaturesKeys;
  links: {
    text: string;
    url: string;
    icon: JSX.Element;
  }[];
}

export const useMenu = () => {
  const { t } = useTranslation(['sidebar']);
  const { isFeatureEnabled } = useFeatureFlags();

  const routes: Route[] = [
    {
      text: t('sidebar:contract'),
      icon: <LibraryBooksOutlinedIcon />,
      url: 'contract',
      links: [
        {
          text: t('sidebar:contractList'),
          url: '/employee/contract/list',
          icon: <DescriptionOutlinedIcon />,
        },
        {
          text: t('sidebar:kyc'),
          url: '/employee/contract/compliance',
          icon: <SummarizeOutlinedIcon />,
        },
      ],
    },
    {
      text: t('sidebar:receipts'),
      icon: <ReceiptLongIcon />,
      url: 'receipts',
      links: [
        {
          text: t('sidebar:receiptsList'),
          url: '/employee/receipts/list',
          icon: <AssignmentOutlinedIcon />,
        },
      ],
    },
    {
      text: t('sidebar:visaServices'),
      icon: <ImportContactsIcon />,
      url: 'visa-service',
      featureFlagName: 'visaService',
      links: [
        {
          text: t('sidebar:visaApplicationStatus'),
          url: '/employee/visa-service/list',
          icon: <SmartScreenIcon />,
        },
      ],
    },
    {
      text: t('sidebar:settings'),
      icon: <SettingsOutlinedIcon />,
      url: 'settings',
      links: [
        {
          text: t('sidebar:settings'),
          url: '/employee/settings',
          icon: <SettingsSuggestOutlinedIcon />,
        },
      ],
    },
  ];
  const modifiedRoutes = routes.map((route) => {
    if (route.featureFlagName && !isFeatureEnabled(route.featureFlagName)) {
      return {
        ...route,
        hidden: true,
      };
    }

    return { ...route, hidden: false };
  });
  return { menu: modifiedRoutes };
};

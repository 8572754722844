import React from 'react';
import { createTheme } from '@mui/material';
import type { LinkProps } from '@mui/material/Link';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import { neutral, green, blue, yellow, red } from './designSystem/colors';
import { fontSizes, lineHeights } from './designSystem/typography';
import { LinkBehavior } from './LinkBehavior';

export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      bg: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: 'light',
    common: {
      white: neutral[10],
      black: neutral[100],
    },
    primary: {
      main: green[40],
    },
    info: {
      main: blue[40],
    },
    warning: {
      main: yellow[40],
    },
    error: {
      main: red[40],
    },
    neutral: {
      ...neutral,
    },
    red: {
      ...red,
    },
    green: {
      ...green,
    },
    yellow: {
      ...yellow,
    },
    blue: {
      ...blue,
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    xxxl: {
      fontSize: fontSizes['xxxl'],
      lineHeight: lineHeights['xxxl'],
      fontFamily: 'Poppins, sans-serif',
    },
    xxl: {
      fontSize: fontSizes['xxl'],
      lineHeight: lineHeights['xxl'],
      fontFamily: 'Poppins, sans-serif',
    },
    xl: {
      fontSize: fontSizes['xl'],
      lineHeight: lineHeights['xl'],
      fontFamily: 'Poppins, sans-serif',
    },
    lg: {
      fontSize: fontSizes['lg'],
      lineHeight: lineHeights['lg'],
      fontFamily: 'Poppins, sans-serif',
    },
    md: {
      fontSize: fontSizes['md'],
      lineHeight: lineHeights['md'],
      fontFamily: 'Poppins, sans-serif',
    },
    base: {
      fontSize: fontSizes['base'],
      lineHeight: lineHeights['base'],
      fontFamily: 'Poppins, sans-serif',
    },
    sm: {
      fontSize: fontSizes['sm'],
      lineHeight: lineHeights['sm'],
      fontFamily: 'Poppins, sans-serif',
    },
    xs: {
      fontSize: fontSizes['xs'],
      lineHeight: lineHeights['xs'],
      fontFamily: 'Poppins, sans-serif',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '& .MuiCardHeader-root ': {
            borderBottom:
              ownerState.variant === 'outlined'
                ? `solid 1px ${theme.palette.neutral[40]}`
                : 'none',
          },
        }),
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          background: theme.palette.green[40],
          padding: '20px 35px',

          // TRANSPARENT BG (eg. registration)
          ...(ownerState.className === 'transparent' && {
            backgroundColor: 'transparent',
            height: '100%',
            '& .MuiStepConnector-root': {
              '& .MuiStepConnector-line': {
                borderColor: theme.palette.green[10],
              },
              '&.Mui-active': {
                '& .MuiStepConnector-line': {
                  borderColor: theme.palette.primary.main,
                },
              },
            },
          }),
        }),
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: ({ theme, ownerState }) => ({
          color: theme.palette.green[30],
          display: 'none',
          [theme.breakpoints.up('lg')]: {
            display: 'block',
          },
          '&.Mui-completed': {
            color: theme.palette.neutral[10],
          },
          '&.Mui-active': {
            color: theme.palette.neutral[10],
          },

          // TRANSPARENT BG (eg. registration)
          ...(ownerState.className === 'transparent' && {
            color: theme.palette.neutral[90],

            '&.Mui-active': {
              color: theme.palette.primary.main,
            },
            '&.Mui-completed': {
              color: theme.palette.primary.main,
            },
          }),
        }),
        root: ({ theme, ownerState }) => ({
          // TRANSPARENT BG (eg. registration)
          ...(ownerState.className === 'transparent' && {
            '& .MuiStepIcon-root': {
              fill: ownerState.active
                ? theme.palette.primary.main
                : theme.palette.green[10],
              '&.Mui-completed': {
                fill: theme.palette.primary.main,
              },
            },
            '& .MuiStepIcon-text': {
              fill: ownerState.active
                ? theme.palette.neutral[10]
                : theme.palette.primary.main,
            },
          }),
        }),
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          background: 'inherit',
          fill: theme.palette.green[30],
          width: 34,
          height: 34,
          [theme.breakpoints.up('md')]: {
            width: 40,
            height: 40,
          },
          ...(ownerState.completed && {
            fill: theme.palette.neutral[10],
          }),
          ...(ownerState.active && {
            fill: theme.palette.neutral[10],
          }),
        }),
        text: ({ theme, ownerState }) => ({
          fill: theme.palette.neutral[10],
          ...(ownerState.active && {
            fill: theme.palette.green[40],
          }),
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line':
            {
              borderColor: theme.palette.neutral[10],
            },
        }),
        line: ({ theme }) => ({
          borderWidth: 1.5,
          borderColor: theme.palette.green[30],
        }),
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.neutral[70],
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.base.fontSize,
          fontWeight: theme.typography.fontWeightMedium,
          letterSpacing: 2,
          paddingBlock: 12,
          marginTop: 0,
          boxShadow: 'none',
          [theme.breakpoints.down('md')]: {
            fontSize: theme.typography.sm.fontSize,
          },
        }),
        containedWarning: ({ theme }) => ({
          textTransform: 'none',
          color: theme.palette.common.white,
        }),
        outlinedWarning: ({ theme }) => ({
          textTransform: 'none',
          backgroundColor: theme.palette.common.white,
          '&:hover': {
            backgroundColor: theme.palette.common.white,
          },
        }),
        startIcon: () => ({
          '& > *:nth-of-type(1)': {
            fontSize: '24px',
          },
        }),
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: ({ theme }) => ({
          textDecoration: 'none',
          cursor: 'pointer',
          [theme.breakpoints.down('md')]: {
            ...theme.typography.sm,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: ({ theme }) => ({
          height: '19px',
          backgroundColor: theme.palette.common.white,
          '&::placeholder': {
            color: theme.palette.neutral[60],
            opacity: 1,
          },
          [theme.breakpoints.down('md')]: {
            padding: '12px',
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: ({ theme }) => ({
          borderColor: theme.palette.neutral[40],
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '& input': {
            borderRadius: '12px',
          },
          '& input:disabled': {
            background: theme.palette.neutral[30],
          },
          '& .Mui-disabled': {
            background: theme.palette.neutral[30],
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.neutral[60],
          '& .MuiSvgIcon-root': {
            color: theme.palette.neutral[60],
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme, ownerState }) => {
          if (ownerState.color === 'info')
            return {
              color: theme.palette.neutral[10],
              '& .MuiSvgIcon-root': {
                color: theme.palette.common.white,
                fontSize: 16,
              },
            };
          return {
            backgroundColor: theme.palette.yellow[40],
            color: theme.palette.common.white,
            padding: '6px 16px',
            height: '36px',
          };
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        icon: false,
      },
      styleOverrides: {
        root: {
          padding: '10px 20px',
        },
        message: ({ theme }) => ({
          padding: 0,
          fontSize: theme.typography.xs.fontSize,
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.yellow[60],
          backgroundColor: theme.palette.yellow[10],
        }),
        standardError: ({ theme }) => ({
          color: theme.palette.red[40],
          backgroundColor: theme.palette.red[10],
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          color: theme.palette.neutral[100],
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiSvgIcon-root': {
            fill: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 44,
          height: 24,
          padding: 0,
        },
        switchBase: ({ theme, ownerState }) => {
          let colorValue = theme.palette.green[40];

          switch (ownerState.color) {
            case 'info':
              colorValue = theme.palette.info.main;
              break;
            case 'warning':
              colorValue = theme.palette.warning.main;
              break;
            case 'error':
              colorValue = theme.palette.error.main;
              break;
          }
          return {
            padding: 0,
            margin: 2,
            '&.Mui-checked': {
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: colorValue,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
          };
        },
        thumb: {
          boxSizing: 'border-box',
          width: 20,
          height: 20,
        },
        track: ({ theme }) => ({
          borderRadius: 24 / 2,
          backgroundColor: theme.palette.neutral[40],
          opacity: 1,
        }),
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownOutlinedIcon,
      },
      styleOverrides: {
        select: {
          minHeight: '19px',
          height: '19px',
        },
        icon: ({ theme }) => ({
          fill: theme.palette.neutral[100],
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        disableClearable: true,
        disablePortal: true,
        popupIcon: <KeyboardArrowDownOutlinedIcon />,
      },
      styleOverrides: {
        endAdornment: ({ theme }) => ({
          '& .MuiSvgIcon-root': {
            fill: theme.palette.neutral[100],
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '40px 80px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.xxl,
          color: theme.palette.neutral[100],
          padding: 0,
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: () => ({
          color: 'inherit',
        }),
        colorError: ({ theme }) => ({
          color: theme.palette.red[50],
        }),
      },
    },
  },
});

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Box,
  Stack,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  criteriaItemStyles,
  inputStyles,
  passwordInformationStyles,
  checkCircleStyles,
} from './index.styles';

import { useIsEmailTaken } from '~/Shared/domain/user/hooks/useIsEmailTaken';
import {
  type TRegisterCredentials,
  registerCredentialsSchema,
  registerCredentialsInit,
} from '~/Shared/domain/user/components/RegisterCredentialsForm/schema';
import { TextField } from '~/Shared/components/Forms/ControlledInputs/TextField';
import { usePasswordFlags } from '~/Shared/domain/user/hooks/usePasswordFlags';

interface IProps {
  onSubmit: SubmitHandler<TRegisterCredentials>;
}

export const RegisterCredentialsForm: React.FC<IProps> = ({ onSubmit }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { t } = useTranslation(['common', 'user']);
  const { mutateAsync } = useIsEmailTaken();

  const { handleSubmit, control, watch, setError } =
    useForm<TRegisterCredentials>({
      defaultValues: registerCredentialsInit,
      resolver: zodResolver(registerCredentialsSchema),
      mode: 'onBlur',
    });

  const { passwordFlags } = usePasswordFlags(watch('password').trim());

  const togglePasswordVisibility = () => setIsPasswordVisible((prev) => !prev);

  const submit: SubmitHandler<TRegisterCredentials> = async (data) => {
    const { taken } = await mutateAsync(data.email);

    if (taken) {
      setError(
        'email',
        { type: 'custom', message: t('user:emailTaken') },
        { shouldFocus: true }
      );
    } else {
      onSubmit(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack spacing="15px">
        <Stack direction="row" spacing="15px" mt="15px">
          <TextField<TRegisterCredentials>
            name="firstName"
            control={control}
            placeholder={t('user:firstName')}
            sx={inputStyles}
            data-testid="input-firstName"
          />
          <TextField<TRegisterCredentials>
            name="middleName"
            control={control}
            placeholder={t('user:middleName')}
            sx={inputStyles}
            data-testid="input-middleName"
          />
          <TextField<TRegisterCredentials>
            name="lastName"
            control={control}
            placeholder={t('user:lastName')}
            sx={inputStyles}
            data-testid="input-lastName"
          />
        </Stack>
        <TextField<TRegisterCredentials>
          name="email"
          control={control}
          type="email"
          placeholder={t('user:email')}
          sx={inputStyles}
          startAdornment={
            <InputAdornment position="start">
              <MailOutlineIcon />
            </InputAdornment>
          }
          data-testid="input-email"
        />
        <TextField<TRegisterCredentials>
          name="password"
          control={control}
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder={t('user:password')}
          sx={inputStyles}
          displayErrorMessage={false}
          startAdornment={
            <InputAdornment position="start">
              <LockOutlinedIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} edge="end">
                {isPasswordVisible ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          }
          data-testid="input-password"
        />
        <Box>
          <Typography sx={passwordInformationStyles}>
            {t('user:passwordMustHave')}
          </Typography>
          <Box>
            {passwordFlags.map(({ value, label }, index) => (
              <Typography key={index} sx={criteriaItemStyles}>
                <CheckCircleIcon sx={checkCircleStyles({ value })} />
                {label}
              </Typography>
            ))}
          </Box>
        </Box>
        <Button type="submit" variant="contained" data-testid="btn-next">
          {t('nextStep')}
        </Button>
      </Stack>
    </form>
  );
};

import { type TStyles } from '~/Shared/types/TStyles';

export const stackStyles: TStyles = {
  mt: 2,
};

export const typographyStyles: TStyles = {
  color: ({ palette }) => palette.neutral[100],
  fontWeight: ({ typography }) => typography.fontWeightBold,
  marginBottom: '10px',
};

export const textFieldStyles: TStyles = {
  mt: 0,
};

export const stackSelectsStyles: TStyles = {
  mt: '0 !important',
};

export const selectStyles: TStyles = {
  minWidth: '120px',
  maxWidth: '120px',
  alignSelf: 'flex-start',
};

export const boxStyles: TStyles = {
  display: 'flex',
  width: '100%',
  justifyItems: 'center',
  alignItems: 'center',
  marginTop: '30px',
  columnGap: '8px',
};

export const buttonStyles: TStyles = {
  flex: 1,
};

export const fontSizes = {
  xxxl: '40px',
  xxl: '33px',
  xl: '28px',
  lg: '23px',
  md: '19px',
  base: '16px',
  sm: '14px',
  xs: '11px',
};

export const lineHeights = {
  xxxl: '60px',
  xxl: '49.5px',
  xl: '42px',
  lg: '36px',
  md: '28.5px',
  base: '24px',
  sm: '20px',
  xs: '16.5px',
};

//eslint-disable-next-line
const removeEmptyFields = (data: Record<PropertyKey, any>) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
};
//eslint-disable-next-line
const clearEmptyObjects = (obj: Record<PropertyKey, any>) => {
  for (const key in obj) {
    if (!obj[key] || typeof obj[key] !== 'object') {
      continue;
    }

    clearEmptyObjects(obj[key]);
    if (Object.keys(obj[key]).length === 0) {
      delete obj[key];
    }
  }
  return obj;
};
// TODO
//eslint-disable-next-line
export const sanitizeOutput = (obj: Record<PropertyKey, any>) => {
  for (const key in obj) {
    removeEmptyFields(obj[key]);
    clearEmptyObjects(obj);
  }

  Object.keys(obj).forEach((key) => {
    for (const n_key in obj[key]) {
      removeEmptyFields(obj[key][n_key]);
    }

    clearEmptyObjects(obj[key]);
  });
};

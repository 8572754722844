import { type TStyles } from '~/Shared/types/TStyles';
import { constants } from '~/Shared/utils/layoutConstants';

export const boxStyles: TStyles = {
  width: '100%',
  position: 'sticky',
  zIndex: 10,
  top: 0,
  right: 0,
  height: {
    xs: constants.mobileHeaderHeight,
    md: '100px',
  },
  background: ({ palette }) => palette.common.white,
  borderBottom: ({ palette }) => `1px solid ${palette.neutral[40]}`,
  px: {
    xs: 2.5,
    md: 5,
  },
  display: 'flex',
};

export const boxImageStyles: TStyles = {
  mr: '2rem',
  display: 'flex',
  alignItems: 'center',
};

export const stepperStyles: TStyles = { width: '100%' };

export const container: TStyles = {
  backgroundColor: ({ palette }) => palette.neutral[20],
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
};

export const stepsWrapStyles: TStyles = {
  width: '700px',
  background: ({ palette }) => palette.common.white,
  padding: '40px 60px',
  marginBlock: '60px',
  borderRadius: '24px',
};

export const copyrightWrapStyles: TStyles = {
  height: '60px',
  backgroundColor: ({ palette }) => palette.common.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

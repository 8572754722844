import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BugReportIcon from '@mui/icons-material/BugReport';

import {
  iconStyles,
  iconWrapperCriticalStyles,
  paperStyles,
  paragraphStyles,
  stackStyles,
  titleStyles,
} from '~/Shared/components/ErrorBoundary/index.styles';

export const CriticalError = () => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ borderRadius: '5px', overflow: 'hidden' }}>
        <Paper elevation={0} sx={paperStyles}>
          <Stack sx={stackStyles}>
            <Box sx={iconWrapperCriticalStyles}>
              <BugReportIcon sx={iconStyles} />
            </Box>
            <Typography variant="h4" sx={titleStyles}>
              {t('common:criticalErrorTitle')}
            </Typography>
            <Typography variant="base" sx={paragraphStyles}>
              {t('common:criticalErrorParagraph')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                navigate('/');
                navigate(0);
              }}
            >
              {t('common:errorButtonText')}
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};

import { object, string, type TypeOf } from 'zod';

export const organizationSchema = object({
  name: string().nonempty(),
  size: string().nonempty(),
});

export type TOrganization = TypeOf<typeof organizationSchema>;

export const organizationInit: TOrganization = {
  name: '',
  size: '',
};

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface IPasswordFlags {
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  hasTwelveChars: boolean;
}

const INIT_FLAGS: IPasswordFlags = {
  hasUppercase: false,
  hasLowercase: false,
  hasNumber: false,
  hasSpecialChar: false,
  hasTwelveChars: false,
};

export const usePasswordFlags = (passwordValue: string) => {
  const { t } = useTranslation(['common', 'user']);

  const [passwordFlags, setPasswordFlags] =
    useState<IPasswordFlags>(INIT_FLAGS);

  useEffect(() => {
    setPasswordFlags(
      passwordValue?.length
        ? {
            hasTwelveChars: passwordValue.length >= 12,
            hasUppercase: /[A-Z]/.test(passwordValue),
            hasLowercase: /[a-z]/.test(passwordValue),
            hasNumber: /\d/.test(passwordValue),
            hasSpecialChar: /[^A-Za-z0-9]/.test(passwordValue),
          }
        : INIT_FLAGS
    );
  }, [passwordValue]);

  const passwordFlagsArr = [
    {
      label: t('user:atLeast12Characters'),
      value: passwordFlags.hasTwelveChars,
    },
    { label: t('user:atLeast1Lowercase'), value: passwordFlags.hasLowercase },
    { label: t('user:atLeast1Uppercase'), value: passwordFlags.hasUppercase },
    { label: t('user:atLeast1Number'), value: passwordFlags.hasNumber },
    { label: t('user:atLeast1Special'), value: passwordFlags.hasSpecialChar },
  ];

  return {
    passwordFlags: passwordFlagsArr,
  };
};

import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { getTutorial, updateTutorial } from '~/Employer/domain/tutorial/api';
import type { TTutorialSteps } from '~/Employer/domain/tutorial/api/dto/tutorial';

enum ETutorial {
  TUTORIAL = 'tutorial',
  UPDATE = 'update',
}

export const useTutorial = () => {
  const { data, refetch } = useQuery({
    queryKey: [ETutorial.TUTORIAL],
    queryFn: getTutorial,
  });

  const { mutate } = useMutation({
    mutationFn: (step: TTutorialSteps) => updateTutorial(step),
    onSuccess: () => {
      refetch();
    },
  });

  const isStepDone = (step: TTutorialSteps) => {
    return data?.steps[step] === 'done';
  };

  //temporary
  const isAllDone = useMemo(() => {
    if (!data) return false;
    const { companyInfo, userInfo, createContract } = data.steps;

    return (
      companyInfo === 'done' && userInfo === 'done' && createContract === 'done'
    );
  }, [data]);

  return {
    isStepDone,
    isAllDone,
    refetch,
    updateTutorial: mutate,
  };
};

import React from 'react';
import { Box, FormHelperText, type TextFieldProps } from '@mui/material';
import {
  Controller,
  type Control,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { Label } from '~/Shared/components/Forms/Label';
import { Statement } from '~/Shared/components/Forms/Statement';
import { HelperText } from '~/Shared/components/Forms/HelperText';

type TDatePicker<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  statement?: string;
  helperText?: string;
  readOnly?: boolean;
  testId?: string;
} & TextFieldProps;

export const DatePicker = <T extends FieldValues>({
  name,
  control,
  label,
  placeholder,
  disablePast,
  disableFuture,
  disabled,
  minDate,
  maxDate,
  statement,
  helperText,
  readOnly,
  sx,
  testId,
}: TDatePicker<T>) => {
  return (
    <Box>
      <Label text={label} />
      <Statement text={statement} />
      <HelperText text={helperText} sx={{ m: 0 }} />
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <>
            <DesktopDatePicker<Dayjs>
              sx={{ mb: '20px', mt: '10px', ...sx }}
              slots={{
                openPickerIcon: () => <CalendarTodayOutlinedIcon />,
              }}
              format="YYYY-MM-DD"
              value={value ? dayjs(value) : null}
              onChange={(el) => {
                onChange(el?.format('YYYY-MM-DD'));
              }}
              disabled={disabled || false}
              disablePast={disablePast || false}
              disableFuture={disableFuture || false}
              minDate={minDate ? dayjs(minDate) : undefined}
              maxDate={maxDate ? dayjs(maxDate) : undefined}
              slotProps={{
                textField: {
                  error: !!fieldState?.error,
                  placeholder: placeholder,
                  inputProps: {
                    [`data-testid`]: testId,
                    readOnly,
                  },
                },
              }}
            />
            {fieldState?.error?.message && (
              <FormHelperText
                error
                sx={{
                  m: '3px 14px 0',
                }}
              >
                {fieldState?.error?.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  );
};

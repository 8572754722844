import React, { type PropsWithChildren } from 'react';
import { Box, Card, Typography, useTheme, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TInfoPage =
  | {
      message: string;
      type: 'success' | 'info';
    }
  | {
      message?: string;
      type: 'error';
    };

export const InfoPage: React.FC<PropsWithChildren<TInfoPage>> = ({
  type,
  message,
  children,
}) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.neutral[30],
      }}
    >
      <Card
        elevation={0}
        sx={{
          minWidth: 400,
          maxWidth: 600,
          minHeight: 200,
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack spacing={3}>
          <Typography
            variant="lg"
            sx={{
              color: theme.palette.common.black,
            }}
          >
            {type === 'error' ? message ?? t('errorInfoPage') : message}
          </Typography>
          {children}
        </Stack>
      </Card>
    </Box>
  );
};

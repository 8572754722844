import { useState, useEffect } from 'react';

export const useCountDown = (initialTime: number) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  const startCountDown = () => {
    setTimeLeft(initialTime);
  };

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined;

    if (timeLeft > 0) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [timeLeft]);

  return { timeLeft, startCountDown };
};

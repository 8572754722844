import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { object, string, type TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
  IconButton,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { i18trans } from '~/translation/i18n';
import { useMediaQuery } from '~/Shared/hooks/useMediaQuery';
import { useResetPassword } from '~/Shared/domain/user/hooks/useResetPassword';
import { InfoPage } from '~/Shared/pages/InfoPage';
import { TextField } from '~/Shared/components/Forms/ControlledInputs/TextField';
import { SplitOverlay } from '~/Shared/components/SplitOverlay';
import Background from '~/Shared/assets/reset-illustration-big.png';

const passwordResetSchema = object({
  password: string({
    required_error: i18trans.t('passwordRequired', { ns: 'user' }),
  }).min(8, i18trans.t('passwordTooShort', { ns: 'user' })),
  passwordConfirm: string({
    required_error: i18trans.t('passwordRequired', { ns: 'user' }),
  }).min(8, i18trans.t('passwordTooShort', { ns: 'user' })),
}).refine((data) => data.password === data.passwordConfirm, {
  message: i18trans.t('passwordMatchRequired', { ns: 'user' }),
  path: ['passwordConfirm'],
});

type TPasswordReset = TypeOf<typeof passwordResetSchema>;

export const PasswordResetPage: React.FC = () => {
  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    passwordConfirm: false,
  });
  const { t } = useTranslation('user');
  const theme = useTheme();
  const { isBigScreen, md } = useMediaQuery();
  const navigate = useNavigate();
  const { code } = useParams();
  const { mutate, isSuccess } = useResetPassword();

  const { handleSubmit, control } = useForm<TPasswordReset>({
    resolver: zodResolver(passwordResetSchema),
    mode: 'onBlur',
  });

  const togglePasswordVisibility = (key: string) => {
    setPasswordVisible((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onSubmit: SubmitHandler<TPasswordReset> = (data) => {
    mutate({
      password: data.password,
      code,
    });
  };

  if (isSuccess) {
    return (
      <InfoPage type="info" message={t('resetPasswordSuccessful')}>
        <Button onClick={() => navigate('/login')} variant="outlined">
          {t('backToLogin')}
        </Button>
      </InfoPage>
    );
  }

  return (
    <SplitOverlay background={Background}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant={isBigScreen ? 'xxxl' : 'lg'}
              sx={{
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.common.black,
              }}
            >
              {t('resetPassword')}
            </Typography>
            <Typography
              variant={isBigScreen ? 'base' : 'xs'}
              sx={{ marginTop: 1 }}
            >
              {t('setNewPassword')}
            </Typography>
          </Box>
          <Stack
            spacing={2}
            sx={{
              mt: '30px',
              mb: '10px',
              [md]: {
                mt: '10px',
              },
            }}
          >
            <TextField<TPasswordReset>
              name="password"
              control={control}
              type={passwordVisible.password ? 'text' : 'password'}
              placeholder={t('password')}
              autoComplete="on"
              sx={{ mt: 0 }}
              startAdornment={
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility('password')}
                    edge="end"
                  >
                    {passwordVisible.password ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <TextField<TPasswordReset>
              name="passwordConfirm"
              control={control}
              type={passwordVisible.passwordConfirm ? 'text' : 'password'}
              placeholder={t('passwordConfirm')}
              autoComplete="on"
              sx={{ mt: 0 }}
              startAdornment={
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => togglePasswordVisibility('passwordConfirm')}
                    edge="end"
                  >
                    {passwordVisible.passwordConfirm ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Button variant="contained" type="submit">
              {t('resetPassword')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </SplitOverlay>
  );
};

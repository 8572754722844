import type { FC } from 'react';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import * as styles from './openTutorial.styles';

interface IProps {
  onClick: () => void;
}
export const OpenTutorial: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation(['common']);
  return (
    <Button
      onClick={onClick}
      sx={styles.btnWrap}
      endIcon={<ExpandCircleDownIcon sx={styles.icon} />}
    >
      <Typography textTransform={'capitalize'} color={'white'}>
        {t('toDoTask')}
      </Typography>
    </Button>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, type SubmitHandler } from 'react-hook-form';
import {
  Box,
  Button,
  FormGroup,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material';

import {
  buttonBoxStyles,
  buttonStyles,
  datePickerStyles,
  personalDetailsTypoStyles,
  selectStyles,
  stackSelectsStyles,
  textFieldStyles,
} from './index.styles';

import {
  type TPersonal,
  personalSchema,
} from '~/Employee/domain/user/components/Register/steps/PersonalDetails/schema';
import { Label } from '~/Shared/components/Forms/Label';
import { createFlag } from '~/Shared/utils/createFlag';
import { useCountriesList } from '~/Shared/domain/countries/hooks';
import { TextField } from '~/Shared/components/Forms/ControlledInputs/TextField';
import { DatePicker } from '~/Shared/components/Forms/ControlledInputs/DatePicker';
import { Select } from '~/Shared/components/Forms/ControlledInputs/Select';
import { contractCountries } from '~/Shared/config/contractCountries';

interface IProps {
  state: TPersonal;
  setState: React.Dispatch<React.SetStateAction<TPersonal>>;
  next: () => void;
}

export const PersonalDetails = ({ state, setState, next }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'user']);

  const { control, handleSubmit, watch } = useForm<TPersonal>({
    defaultValues: state,
    resolver: zodResolver(personalSchema),
    mode: 'onBlur',
  });

  const mobilePhoneCode = watch('personal.mobilePhone.code');
  const homePhoneCode = watch('personal.homePhone.code');

  const { countries, isError, isLoading } = useCountriesList();
  const genders = ['female', 'male', 'nonBinary', 'undisclosed'] as const;

  const onSubmit: SubmitHandler<TPersonal> = (data) => {
    data.personal.mobilePhone.area =
      countries?.find((country) => country.alpha2Code === mobilePhoneCode)
        ?.callingCodes[0] || '';
    data.personal.homePhone.area =
      countries?.find((country) => country.alpha2Code === homePhoneCode)
        ?.callingCodes[0] || '';

    setState(data);
    next();
  };

  const sortedCountriesByDialCode = countries
    ?.slice()
    .sort((a, b) => Number(a.callingCodes[0]) - Number(b.callingCodes[0]));

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="xxl" sx={personalDetailsTypoStyles}>
        {t('user:personalDetails')}
      </Typography>
      <Typography>{t('user:personalDetailsSubtitle')}</Typography>
      <FormGroup>
        <Stack mt={2} spacing={2}>
          <Select<TPersonal>
            name="personal.country"
            control={control}
            disabled={isError || isLoading}
            label={t('user:citizenshipLabel')}
            placeholder={t('user:citizenshipPlaceholder')}
            displayEmpty
            fullWidth
          >
            {countries?.map((country) => (
              <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>

          <Select<TPersonal>
            name="personal.workCountry"
            control={control}
            label={t('user:workCountryLabel')}
            placeholder={t('user:workCountryPlaceholder')}
            displayEmpty
            fullWidth
          >
            {contractCountries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>

          <DatePicker<TPersonal>
            name="personal.birth.date"
            control={control}
            label={t('user:birthdayLabel')}
            placeholder={t('user:datePlaceholder')}
            disableFuture
            sx={datePickerStyles}
          />

          <Select<TPersonal>
            name="personal.birth.place"
            control={control}
            disabled={isError || isLoading}
            label={t('user:placeOfBirthLabel')}
            placeholder={t('user:placeOfBirthPlaceholder')}
            displayEmpty
            fullWidth
          >
            {countries?.map((country) => (
              <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>

          <Label text={t('user:mobilePhoneLabel')} />
          <Stack direction="row" spacing={1} sx={stackSelectsStyles}>
            <Select<TPersonal>
              name="personal.mobilePhone.code"
              control={control}
              placeholder={t('user:phoneAreaPlaceholder')}
              displayEmpty
              sx={selectStyles}
            >
              {sortedCountriesByDialCode?.map((country) => (
                <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                  {createFlag(country.alpha2Code)}&nbsp; +
                  {country.callingCodes[0]}
                </MenuItem>
              ))}
            </Select>

            <TextField<TPersonal>
              name="personal.mobilePhone.number"
              control={control}
              placeholder={t('phonePlaceholder')}
              type="number"
              fullWidth
              sx={textFieldStyles}
            />
          </Stack>

          <Label text={t('user:homePhoneLabel')} />
          <Stack direction="row" spacing={1} sx={stackSelectsStyles}>
            <Select<TPersonal>
              name="personal.homePhone.code"
              control={control}
              placeholder={t('phoneAreaPlaceholder')}
              displayEmpty
              sx={selectStyles}
            >
              {sortedCountriesByDialCode?.map((country) => (
                <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                  {createFlag(country.alpha2Code)}&nbsp; +
                  {country.callingCodes[0]}
                </MenuItem>
              ))}
            </Select>

            <TextField<TPersonal>
              name="personal.homePhone.number"
              control={control}
              placeholder={t('phonePlaceholder')}
              type="number"
              fullWidth
              sx={textFieldStyles}
            />
          </Stack>

          <TextField<TPersonal>
            name="personal.passport"
            label={t('user:passportNumberLabel')}
            placeholder={t('user:passportNumberPlaceholder')}
            control={control}
            sx={textFieldStyles}
          />

          <Select<TPersonal>
            name="personal.gender"
            control={control}
            label={t('user:genderLabel')}
            placeholder={t('user:genderPlaceholder')}
            displayEmpty
            fullWidth
          >
            {genders?.map((gender) => (
              <MenuItem key={gender} value={gender}>
                {t(gender)}
              </MenuItem>
            ))}
          </Select>

          <TextField<TPersonal>
            name="personal.nickname"
            label={t('user:nicknameLabel')}
            placeholder={t('user:nicknamePlaceholder')}
            control={control}
            sx={textFieldStyles}
          />
        </Stack>
      </FormGroup>
      <Box sx={buttonBoxStyles}>
        <Button
          sx={buttonStyles}
          variant="outlined"
          onClick={() => navigate('/register')}
        >
          {t('back')}
        </Button>
        <Button sx={buttonStyles} variant="contained" type="submit">
          {t('next')}
        </Button>
      </Box>
    </Box>
  );
};

import { Box, Button, Typography } from '@mui/material';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './task.styles';

interface IProps {
  title: string;
  onClick: () => void;
  buttonTitle: string;
  isDone: boolean;
}

export const Task: FC<IProps> = ({ title, onClick, buttonTitle, isDone }) => {
  const { t } = useTranslation(['tutorial']);

  return (
    <Box sx={styles.task}>
      <Typography sx={styles.title}>{title}</Typography>
      <Button onClick={onClick} sx={styles.button} disabled={isDone}>
        <Typography sx={styles.buttonTitle}>
          {isDone ? t('tutorial:done') : buttonTitle}
        </Typography>
      </Button>
    </Box>
  );
};

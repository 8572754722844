import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useWhoAmI } from '~/Shared/domain/user/hooks/useWhoAmI';
import { Loader } from '~/Shared/components/Loader';

export const EmployeeOutlet = (): React.ReactElement => {
  const { isLoading, isError, data } = useWhoAmI();
  if (isLoading) {
    return <Loader height="100vh" />;
  }
  if (isError || data?.type !== 'employee') {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

import type { TStyles } from '~/Shared/types/TStyles';

export const btnWrap: TStyles = {
  backgroundColor: ({ palette }) => palette.blue[40],
  width: '160px',
  height: '54px',
  borderRadius: '100px',
  padding: '10px',
  position: 'fixed',
  bottom: '40px',
  right: '40px',
  zIndex: 100,
  color: ({ palette }) => palette.common.white,
  ':hover': {
    backgroundColor: ({ palette }) => palette.blue[50],
  },
};

export const icon: TStyles = {
  width: '30px',
  height: '30px',
  transform: 'rotate(180deg)',
};

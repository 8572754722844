import {
  Box,
  Button,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import {
  titleBoxStyles,
  titleStyles,
  fieldsWrapperStyles,
  linkStyles,
  signUpBoxStyles,
  subtitleStyles,
  inputNameStyles,
} from './index.styles';

import { TextField } from '~/Shared/components/Forms/ControlledInputs/TextField';
import {
  type TOrganization,
  organizationSchema,
  organizationInit,
} from '~/Employer/domain/user/components/Register/steps/Organization/schema';
import { SplitOverlay } from '~/Shared/components/SplitOverlay';
import { useMediaQuery } from '~/Shared/hooks/useMediaQuery';
import IllustrationBig from '~/Shared/assets/login-illustration-big.png';
import organizationSize from '~/Shared/config/organizationSize.json';
import { Select } from '~/Shared/components/Forms/ControlledInputs/Select';
import { useRegisterEmployer } from '~/Shared/domain/user/hooks/useRegisterEmployer';

export const Organization: React.FC = () => {
  const { t } = useTranslation(['user', 'common']);
  const { state: credentialsState } = useLocation();
  const { isBigScreen } = useMediaQuery();
  const { mutate } = useRegisterEmployer(credentialsState.email);

  const { handleSubmit, control } = useForm<TOrganization>({
    defaultValues: organizationInit,
    resolver: zodResolver(organizationSchema),
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<TOrganization> = (data) => {
    const sendData = {
      ...credentialsState,
      organization: { ...data },
    };

    mutate(sendData);
  };

  return (
    <SplitOverlay background={IllustrationBig}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={titleBoxStyles}>
          <Typography variant={isBigScreen ? 'xxxl' : 'lg'} sx={titleStyles}>
            {t('user:registerOrganizationTitle')}
          </Typography>
          <Typography variant={isBigScreen ? 'base' : 'xs'} sx={subtitleStyles}>
            {t('user:registerOrganizationSubtitle')}
          </Typography>
        </Box>
        <Stack spacing={2} sx={fieldsWrapperStyles}>
          <TextField<TOrganization>
            name="name"
            type="text"
            control={control}
            placeholder={t('user:placeholderOrganizationName')}
            sx={inputNameStyles}
            startAdornment={
              <InputAdornment position="start">
                <CorporateFareIcon />
              </InputAdornment>
            }
            data-testid="input-organizationName"
          />
          <Select<TOrganization>
            name="size"
            control={control}
            placeholder={t('user:organizationPlaceholder')}
            displayEmpty
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <PeopleAltIcon />
              </InputAdornment>
            }
            data-testid="select-organizationSize"
          >
            {organizationSize.map((el) => (
              <MenuItem key={el.value} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
          <Button variant="contained" type="submit" data-testid="btn-next">
            {t('next')}
          </Button>
        </Stack>
        <Box sx={signUpBoxStyles}>
          <Typography variant={isBigScreen ? 'base' : 'xs'}>
            {t('user:haveAccount')}
          </Typography>
          <Link component={RouterLink} to="/login" sx={linkStyles}>
            {t('user:signIn')}
          </Link>
        </Box>
      </Box>
    </SplitOverlay>
  );
};

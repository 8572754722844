import React from 'react';
import { Box, useTheme } from '@mui/material';

import { RegisterSuccess } from '~/Shared/domain/user/components/RegisterSuccess';
import { Copyright } from '~/Shared/components/Copyright';
import logoRemote from '~/Shared/assets/logo-remote.svg';

export const RegisterSuccessPage: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          height: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src={logoRemote}
          alt="Infinity Remote"
          sx={{
            pointerEvents: 'none',
          }}
        />
      </Box>
      <RegisterSuccess />
      <Box
        sx={{
          height: '60px',
          backgroundColor: theme.palette.common.white,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Copyright />
      </Box>
    </Box>
  );
};

/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { wrapUseRoutes } from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

import { version } from '../package.json';

import { employeeRoutes } from '~/Employee/pages';
import { employerRoutes } from '~/Employer/pages';
import { sharedRoutes } from '~/Shared/pages';
import { ThemeProviderCustom } from '~/Shared/styles/context';
import './index.css';
import './translation/i18n';
import './axiosConfig';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { StyledSnackbarProvider } from '~/Shared/components/StyledSnackbarProvider';

//eslint-disable-next-line
// console.log('envy------++----', import.meta.env);
//eslint-disable-next-line
// console.log('app version', version);

Sentry.init({
  dsn: 'https://a19ff9158f2b4bafa2ea6bf5086dddab@o1294427.ingest.sentry.io/4504123732983808',
  release: version,
  tracesSampleRate:
    import.meta.env.VITE_APP_ENVIROMENT === 'production' ? 0.2 : 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: import.meta.env.VITE_APP_ENVIROMENT === 'production',
      blockAllMedia: true,
    }),
  ],
  environment: import.meta.env.VITE_APP_ENVIROMENT,
  enabled: ['production', 'pre-production', 'staging'].includes(
    import.meta.env.VITE_APP_ENVIROMENT || ''
  ),
});

const useSentryRoutes = wrapUseRoutes(useRoutes);
const App: React.FC = () => {
  const element = useSentryRoutes([
    sharedRoutes,
    employeeRoutes,
    employerRoutes,
  ]);
  return element;
};

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 10, cacheTime: 1000 * 20 } },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProviderCustom>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledSnackbarProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </StyledSnackbarProvider>
          </LocalizationProvider>
        </ThemeProviderCustom>
      </StyledEngineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useNotification } from '~/Shared/hooks/useNotification';
import { resetPassword } from '~/Shared/domain/user/api/auth/resetPassword';

export const useResetPassword = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation('notification');

  return useMutation(resetPassword, {
    onError: () => {
      showNotification('error', t('resetPasswordError'));
    },
  });
};

import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import ReactGA from 'react-ga4';

import { SplitOverlay } from '~/Shared/components/SplitOverlay';
import { LoginForm } from '~/Shared/domain/user/LoginForm';
import { useMediaQuery } from '~/Shared/hooks/useMediaQuery';
import IllustrationBig from '~/Shared/assets/login-illustration-big.png';

export const LoginPage: React.FC = () => {
  const { t } = useTranslation('user');
  const theme = useTheme();
  const { isBigScreen } = useMediaQuery();

  useEffect(() => {
    if (import.meta.env.VITE_APP_GA) {
      ReactGA.initialize(import.meta.env.VITE_APP_GA);
      ReactGA.send({ hitType: 'pageview' });
    }
  }, []);

  return (
    <SplitOverlay background={IllustrationBig}>
      <Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant={isBigScreen ? 'xxxl' : 'lg'}
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.common.black,
            }}
          >
            {t('loginTitle')}
          </Typography>
          <Typography
            variant={isBigScreen ? 'base' : 'xs'}
            sx={{ marginTop: 1 }}
          >
            {t('loginSubtitle')}
          </Typography>
        </Box>
        <LoginForm />
        <Box>
          <Box>
            <Typography variant={isBigScreen ? 'base' : 'xs'}>
              {t('createAccount')}
            </Typography>
            <Link
              component={RouterLink}
              to="/register"
              sx={{ marginLeft: 1, fontFamily: 'Poppins, sans-serif' }}
              data-testid="btn-signup"
            >
              {t('signUp')}
            </Link>
          </Box>
          <Box>
            <Typography variant={isBigScreen ? 'base' : 'xs'}>
              {t('forgotPassword')}
            </Typography>
            <Link
              component={RouterLink}
              to="/reset-password"
              sx={{ marginLeft: 1, fontFamily: 'Poppins, sans-serif' }}
            >
              {t('resetPassword')}
            </Link>
          </Box>
        </Box>
      </Box>
    </SplitOverlay>
  );
};

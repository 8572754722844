import type { ITutorialDTO } from '~/Employer/domain/tutorial/api/dto/tutorial';

export const createTutorialModel = (status: ITutorialDTO) => ({
  status: status.status,
  steps: {
    companyInfo: status.steps.companyInfo,
    createContract: status.steps.createContract,
    createTeam: status.steps.createTeam,
    inviteTeamManager: status.steps.inviteTeamManager,
    userInfo: status.steps.userInfo,
  },
});

import axios from 'axios';

import { type ICountry } from '~/Shared/domain/countries/api/countriesDTO';

export const getCountries = async () => {
  const { data } = await axios.get<ICountry[]>(
    'https://restcountries.com/v2/all'
  );
  return data;
};

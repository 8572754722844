import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import { isEmailTaken } from '~/Shared/domain/user/api/auth/isEmailTaken';
import { useNotification } from '~/Shared/hooks/useNotification';

export const useIsEmailTaken = () => {
  const { showNotification } = useNotification();

  return useMutation({
    mutationKey: ['email'],
    mutationFn: isEmailTaken,
    onError: (error) => {
      if (isAxiosError(error)) {
        showNotification('error', error?.response?.data.error);
        return;
      }
    },
  });
};

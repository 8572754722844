import { useSnackbar, type VariantType } from 'notistack';

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showNotification = (type: VariantType, info: string): void => {
    enqueueSnackbar(info, {
      variant: type,
    });
  };

  return {
    showNotification,
  };
};

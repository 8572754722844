import { object, string, type TypeOf } from 'zod';

export const dependentSchema = object({
  dependent: object({
    maritalStatus: string(),
    spouseName: string(),
    passport: string(),
    company: string(),
    startDate: string(),
    endDate: string(),
    salary: string(),
    benefits: string(),
    jobDescription: string(),
  }),
});

export type TDependent = TypeOf<typeof dependentSchema>;

export const dependentInit: TDependent = {
  dependent: {
    maritalStatus: '',
    spouseName: '',
    passport: '',
    company: '',
    startDate: '',
    endDate: '',
    salary: '',
    benefits: '',
    jobDescription: '',
  },
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { logout } from '~/Shared/domain/user/api/auth/logout';

export const useLogout = () => {
  const queryQlient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: ['logout'],
    mutationFn: logout,
    retry: 0,
    onSuccess: () => {
      queryQlient.clear();
      navigate('/login');
    },
    onError: () => {
      queryQlient.clear();

      navigate('/login');
    },
  });
};

import { type TStyles } from '~/Shared/types/TStyles';

export const titleStyles: TStyles = {
  color: ({ palette }) => palette.common.black,
  fontWeight: ({ typography }) => typography.fontWeightBold,
};

export const subtitleStyles: TStyles = {
  marginTop: 1,
};

export const titleBoxStyles: TStyles = {
  display: 'flex',
  flexDirection: 'column',
};

export const fieldsWrapperStyles: TStyles = {
  mt: '30px',
  mb: '10px',
};

export const linkStyles: TStyles = {
  marginLeft: 1,
  fontFamily: 'Poppins, sans-serif',
};

export const inputNameStyles: TStyles = { mt: '2px', mb: 0 };

export const signUpBoxStyles: TStyles = { mt: '16px' };

import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const verifyAccount = async (code: string | undefined) => {
  const { data } = await axios.get<string>(`/auth/verify/${code}`);
  return data;
};

export const useVerifyAccount = (code: string | undefined) => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['verifyAccount'],
    queryFn: () => verifyAccount(code),
  });

  return {
    data,
    isLoading,
    isError,
    isSuccess,
  };
};

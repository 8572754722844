import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export const Loader: React.FC<{ height?: string }> = ({ height = '100px' }) => {
  return (
    <Box
      sx={{
        height: { height },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

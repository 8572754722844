import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import { EmployerOutlet } from '~/Employer/EmployerOutlet';
import { Layout } from '~/Employer/Layout';
import { lazyImport } from '~/Shared/utils/lazyImport';
import { AccessProvider } from '~/Shared/utils/providers/AccessProvider';

const { EmployeeContract } = lazyImport(
  () => import('~/Employer/pages/EmployeeContract'),
  'EmployeeContract'
);
const { FixedRateContract } = lazyImport(
  () => import('~/Employer/pages/FixedRateContract'),
  'FixedRateContract'
);
const { PayGoContract } = lazyImport(
  () => import('~/Employer/pages/PayGoContract'),
  'PayGoContract'
);
const { NewContract } = lazyImport(
  () => import('~/Employer/pages/NewContract'),
  'NewContract'
);
const { InvoicesList } = lazyImport(
  () => import('~/Employer/pages/InvoicesList'),
  'InvoicesList'
);
const { InvoiceDetails } = lazyImport(
  () => import('~/Employer/pages/InvoiceDetails'),
  'InvoiceDetails'
);
const { ReceiptsList } = lazyImport(
  () => import('~/Employer/pages/ReceiptsList'),
  'ReceiptsList'
);
const { ReceiptDetails } = lazyImport(
  () => import('~/Employer/pages/ReceiptDetails'),
  'ReceiptDetails'
);
const { PayslipDetails } = lazyImport(
  () => import('~/Employer/pages/PayslipDetails'),
  'PayslipDetails'
);
const { ContractList } = lazyImport(
  () => import('~/Employer/pages/ContractList'),
  'ContractList'
);
const { ContractDetail } = lazyImport(
  () => import('~/Employer/pages/ContractDetail'),
  'ContractDetail'
);

const { Settings } = lazyImport(
  () => import('~/Employer/pages/settings/Settings'),
  'Settings'
);

const { OrganizationInformationPage } = lazyImport(
  () => import('~/Employer/pages/settings/OrganizationInformation'),
  'OrganizationInformationPage'
);
const { PersonalInformationPage } = lazyImport(
  () => import('~/Employer/pages/settings/PersonalInformation'),
  'PersonalInformationPage'
);

const { FullTimeJob } = lazyImport(
  () => import('~/Employer/pages/Jobs/FullTimeJob'),
  'FullTimeJob'
);

const { JobListings } = lazyImport(
  () => import('~/Employer/pages/Jobs/JobListings'),
  'JobListings'
);

const { NewJobListing } = lazyImport(
  () => import('~/Employer/pages/NewJobListing'),
  'NewJobListing'
);

const { PartTimeJob } = lazyImport(
  () => import('~/Employer/pages/Jobs/PartTimeJob'),
  'PartTimeJob'
);

const { JobListingsDetails } = lazyImport(
  () => import('~/Employer/pages/Jobs/JobListingsDetails'),
  'JobListingsDetails'
);

const { JobListingsResume } = lazyImport(
  () => import('~/Employer/pages/Jobs/JobListingsResume'),
  'JobListingsResume'
);

const { JobListingsDetailsEdit } = lazyImport(
  () => import('~/Employer/pages/Jobs/JobListingsDetailsEdit'),
  'JobListingsDetailsEdit'
);

const { KYCDocumentList } = lazyImport(
  () => import('~/Employer/pages/kyc/DocumentList'),
  'KYCDocumentList'
);

const { KYCEmployeeDocumentList } = lazyImport(
  () => import('~/Employer/pages/kyc/DocumentDetails'),
  'KYCEmployeeDocumentList'
);

const { ImmigrationList } = lazyImport(
  () => import('~/Employer/pages/tracker/ImmigrationList'),
  'ImmigrationList'
);

export enum EEmployerRoutes {
  EMPLOYER = '/employer',
  CONTRACT_LIST = '/employer/contract/list',
  CONTRACT_DETAIL = '/employer/contract/list/:id',
  CONTRACT_NEW = '/employer/contract/new',
  CONTRACT_NEW_EOR = '/employer/contract/new/eor',
  CONTRACT_NEW_FIXED_RATE = '/employer/contract/new/fixed-rate',
  CONTRACT_NEW_PAY_GO = '/employer/contract/new/pay-go',
  INVOICES_LIST = '/employer/invoices/list',
  INVOICE_DETAIL = '/employer/invoices/list/:id',
  RECEIPTS_LIST = '/employer/receipts/list',
  RECEIPT_DETAIL = '/employer/receipts/list/:id',
  RECEIPT_PAYSLIP = '/employer/receipts/payslip/:id',
  JOB_LISTINGS = '/employer/joblisting/list',
  JOB_LISTINGS_NEW = '/employer/joblisting/new',
  JOB_LISTINGS_NEW_FULL_TIME = '/employer/joblisting/new/full-time',
  JOB_LISTINGS_NEW_PART_TIME = '/employer/joblisting/new/part-time',
  JOB_LISTINGS_DETAIL = '/employer/joblisting/list/:id',
  JOB_LISTINGS_DETAIL_EDIT = '/employer/joblisting/list/:id/edit',
  JOB_LISTINGS_RESUME = '/employer/joblisting/resume/:resumeId',
  KYC_DOCUMENTS = '/employer/kyc/documents',
  KYC_DOCUMENTS_DETAIL = '/employer/kyc/documents/:id',
  SETTINGS = '/employer/settings',
  SETTINGS_PERSONAL = '/employer/settings/personal-information',
  SETTING_ORGANIZATION = '/employer/settings/organization-information',
  TRACKER_IMMIGRATION = 'tracker-immigration',
}

export const employerRoutes = {
  path: EEmployerRoutes.EMPLOYER,
  element: (
    <Suspense fallback="loading">
      <EmployerOutlet />
    </Suspense>
  ),
  children: [
    {
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Navigate to={EEmployerRoutes.CONTRACT_NEW} replace />,
        },
        {
          children: [
            // CONTRACTS
            {
              path: EEmployerRoutes.CONTRACT_NEW,
              element: (
                <AccessProvider>
                  <NewContract />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.CONTRACT_NEW_EOR,
              element: (
                <AccessProvider>
                  <EmployeeContract />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.CONTRACT_NEW_FIXED_RATE,
              element: (
                <AccessProvider>
                  <FixedRateContract />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.CONTRACT_NEW_PAY_GO,
              element: (
                <AccessProvider>
                  <PayGoContract />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.CONTRACT_LIST,
              element: (
                <AccessProvider>
                  <ContractList />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.CONTRACT_DETAIL,
              element: (
                <AccessProvider>
                  <ContractDetail />
                </AccessProvider>
              ),
            },
            // INVOICES
            {
              path: EEmployerRoutes.INVOICES_LIST,
              element: (
                <AccessProvider>
                  <InvoicesList />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.INVOICE_DETAIL,
              element: (
                <AccessProvider>
                  <InvoiceDetails />
                </AccessProvider>
              ),
            },
            // RECEIPTS
            {
              path: EEmployerRoutes.RECEIPTS_LIST,
              element: (
                <AccessProvider>
                  <ReceiptsList />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.RECEIPT_DETAIL,
              element: (
                <AccessProvider>
                  <ReceiptDetails />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.RECEIPT_PAYSLIP,
              element: (
                <AccessProvider>
                  <PayslipDetails />
                </AccessProvider>
              ),
            },
            // TRACKER
            {
              path: EEmployerRoutes.TRACKER_IMMIGRATION,
              element: (
                <AccessProvider>
                  <ImmigrationList />
                </AccessProvider>
              ),
            },
            // JOBS
            {
              path: EEmployerRoutes.JOB_LISTINGS_NEW,
              element: (
                <AccessProvider>
                  <NewJobListing />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.JOB_LISTINGS_NEW_FULL_TIME,
              element: (
                <AccessProvider>
                  <FullTimeJob />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.JOB_LISTINGS_NEW_PART_TIME,
              element: (
                <AccessProvider>
                  <PartTimeJob />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.JOB_LISTINGS,
              element: (
                <AccessProvider>
                  <JobListings />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.JOB_LISTINGS_DETAIL,
              element: (
                <AccessProvider>
                  <JobListingsDetails />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.JOB_LISTINGS_DETAIL_EDIT,
              element: (
                <AccessProvider>
                  <JobListingsDetailsEdit />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.JOB_LISTINGS_RESUME,
              element: (
                <AccessProvider>
                  <JobListingsResume />
                </AccessProvider>
              ),
            },
            // KYC
            {
              path: EEmployerRoutes.KYC_DOCUMENTS,
              element: (
                <AccessProvider featureKey="employerComplianceDocument">
                  <KYCDocumentList />
                </AccessProvider>
              ),
            },
            {
              path: EEmployerRoutes.KYC_DOCUMENTS_DETAIL,
              element: (
                <AccessProvider featureKey="employerComplianceDocument">
                  <KYCEmployeeDocumentList />
                </AccessProvider>
              ),
            },
            // SETTINGS
            {
              element: <Settings />,
              path: EEmployerRoutes.SETTINGS,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate to={EEmployerRoutes.SETTINGS_PERSONAL} replace />
                  ),
                },
                {
                  path: EEmployerRoutes.SETTINGS_PERSONAL,
                  element: (
                    <AccessProvider>
                      <PersonalInformationPage />
                    </AccessProvider>
                  ),
                },
                {
                  path: EEmployerRoutes.SETTING_ORGANIZATION,
                  element: (
                    <AccessProvider>
                      <OrganizationInformationPage />
                    </AccessProvider>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

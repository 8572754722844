import React from 'react';
import {
  Box,
  FormHelperText,
  Select as MUISelect,
  type SelectProps,
} from '@mui/material';
import {
  Controller,
  type Control,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';

import { Label } from '~/Shared/components/Forms/Label';
import { SelectPlaceholder } from '~/Shared/components/SelectPlaceholder';

type TSelect<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  label?: string;
  disabled?: boolean;
  displayEmpty?: boolean;
  placeholder: string;
  children: React.ReactNode;
} & SelectProps;

export const Select = <T extends FieldValues>({
  name,
  control,
  label,
  disabled,
  displayEmpty,
  placeholder,
  children,
  sx,
  ...rest
}: TSelect<T>) => {
  return (
    <Box sx={{ ...sx }}>
      <Label text={label} />
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange }, fieldState }) => (
          <>
            <MUISelect
              disabled={disabled || false}
              value={value || ''}
              onChange={onChange}
              displayEmpty={displayEmpty || true}
              error={!!fieldState.error}
              sx={{
                width: '100%',
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
              renderValue={
                value !== ''
                  ? undefined
                  : () => <SelectPlaceholder>{placeholder}</SelectPlaceholder>
              }
              {...rest}
            >
              {children}
            </MUISelect>
            {fieldState?.error?.message && (
              <FormHelperText
                error
                sx={{
                  m: '3px 14px 0',
                }}
              >
                {fieldState?.error?.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  );
};

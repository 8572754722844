import React from 'react';
import { Box, Button, Collapse, Typography, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation } from 'react-router-dom';

import { useBoolean } from '~/Shared/hooks/useBoolean';

interface MenuInterface {
  icon: JSX.Element;
  text: string;
  url: string;
  children: JSX.Element[];
}

export const Menu = ({ icon, text, url, children }: MenuInterface) => {
  const theme = useTheme();
  const location = useLocation();
  const active = location.pathname.split('/')[2] === url;
  const { value, toggle } = useBoolean(active);

  return (
    <>
      <Button
        startIcon={icon}
        variant="text"
        fullWidth
        onClick={toggle}
        sx={{
          justifyContent: 'flex-start',
          px: 4,
          mt: 0,
          mb: 0,
          py: 2,
          position: 'relative',
          background: active
            ? theme.palette.green[40]
            : theme.palette.neutral[10],
          color: active ? theme.palette.neutral[10] : theme.palette.green[40],
          borderRadius: 0,
          '&:hover': {
            background: active
              ? theme.palette.green[50]
              : theme.palette.neutral[20],
          },
        }}
      >
        <Typography
          variant="base"
          sx={{
            textTransform: 'none',
            letterSpacing: 0,
            fontWeight: theme.typography.fontWeightRegular,
            mr: 1,
            color: active ? theme.palette.neutral[10] : theme.palette.green[40],
          }}
        >
          {text}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translate(0, -50%)',
              translate: '0, -50%',
              pr: 2.5,
            }}
          >
            <KeyboardArrowDownIcon
              sx={{
                transform: value ? 'rotate(180deg)' : 'rotate(0deg)',
                color: active
                  ? theme.palette.neutral[10]
                  : theme.palette.green[40],
              }}
            />
          </Box>
        </Typography>
      </Button>
      <Collapse in={value}>
        <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', px: 3 }}>
          {children}
        </Box>
      </Collapse>
    </>
  );
};

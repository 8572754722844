import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useForm, type SubmitHandler } from 'react-hook-form';
import {
  Box,
  Button,
  FormGroup,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material';

import {
  boxStyles,
  buttonStyles,
  selectStyles,
  stackSelectsStyles,
  stackStyles,
  textFieldStyles,
  typographyStyles,
} from './index.styles';

import {
  type TBank,
  bankSchema,
} from '~/Employee/domain/user/components/Register/steps/BankDetails/schema';
import { Label } from '~/Shared/components/Forms/Label';
import { createFlag } from '~/Shared/utils/createFlag';
import { useCountriesList } from '~/Shared/domain/countries/hooks';
import { TextField } from '~/Shared/components/Forms/ControlledInputs/TextField';
import { Select } from '~/Shared/components/Forms/ControlledInputs/Select';

interface IProps {
  state: TBank;
  setState: React.Dispatch<React.SetStateAction<TBank>>;
  next: () => void;
  back: () => void;
}

export const BankDetails = ({ state, setState, next, back }: IProps) => {
  const { t } = useTranslation(['common', 'user']);

  const { control, handleSubmit, getValues, watch } = useForm<TBank>({
    defaultValues: state,
    resolver: zodResolver(bankSchema),
    mode: 'onBlur',
  });
  const phoneCode = watch('bank.emergencyContact.phone.code');

  const { countries, isError, isLoading } = useCountriesList();
  const relationships = [
    'mother',
    'father',
    'brother',
    'sister',
    'friend',
  ] as const;

  const onSubmit: SubmitHandler<TBank> = (data) => {
    data.bank.emergencyContact.phone.area =
      countries?.find((country) => country.alpha2Code === phoneCode)
        ?.callingCodes[0] || '';
    setState(data);
    next();
  };

  const sortedCountriesByDialCode = countries
    ?.slice()
    .sort((a, b) => Number(a.callingCodes[0]) - Number(b.callingCodes[0]));

  const onBack = () => {
    setState(getValues());
    back();
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="xxl" sx={typographyStyles}>
        {t('bankDetails')}
      </Typography>
      <Typography>{t('user:bankDetailsSubtitle')}</Typography>
      <FormGroup>
        <Stack spacing={2} sx={stackStyles}>
          <TextField<TBank>
            name="bank.name"
            placeholder={t('user:bankNamePlaceholder')}
            label={t('user:bankNameLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <TextField<TBank>
            name="bank.branchName"
            placeholder={t('user:branchNamePlaceholder')}
            label={t('user:branchNameLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <Select<TBank>
            name="bank.country"
            control={control}
            disabled={isError || isLoading}
            label={t('user:countryLabel')}
            placeholder={t('user:countryPlaceholder')}
            displayEmpty
            fullWidth
          >
            {countries?.map((country) => (
              <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                {country.name}
              </MenuItem>
            ))}
          </Select>

          <TextField<TBank>
            name="bank.accountName"
            placeholder={t('user:accountNamePlaceholder')}
            label={t('user:accountNameLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <TextField<TBank>
            name="bank.accountNumber"
            placeholder={t('user:accountNumberPlaceholder')}
            label={t('user:accountNumberLabel')}
            control={control}
            type="number"
            sx={textFieldStyles}
          />

          <TextField<TBank>
            name="bank.swift"
            placeholder={t('user:swiftPlaceholder')}
            label={t('user:swiftLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <TextField<TBank>
            name="bank.emergencyContact.person"
            placeholder={t('user:emergencyContactPlaceholder')}
            label={t('user:emergencyContactLabel')}
            control={control}
            sx={textFieldStyles}
          />

          <Select<TBank>
            name="bank.emergencyContact.relationshipType"
            control={control}
            label={t('user:relationshipTypeLabel')}
            placeholder={t('user:relationshipTypePlaceholder')}
            displayEmpty
            fullWidth
          >
            {relationships?.map((relation) => (
              <MenuItem key={relation} value={relation}>
                {t(relation)}
              </MenuItem>
            ))}
          </Select>

          <Label text={t('user:emergencyNumberLabel')} />
          <Stack direction="row" spacing={1} sx={stackSelectsStyles}>
            <Select<TBank>
              name="bank.emergencyContact.phone.code"
              control={control}
              placeholder={t('phoneAreaPlaceholder')}
              displayEmpty
              sx={selectStyles}
            >
              {sortedCountriesByDialCode?.map((country) => (
                <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                  {createFlag(country.alpha2Code)}&nbsp; +
                  {country.callingCodes[0]}
                </MenuItem>
              ))}
            </Select>

            <TextField<TBank>
              name="bank.emergencyContact.phone.number"
              control={control}
              placeholder={t('phonePlaceholder')}
              type="number"
              fullWidth
              sx={textFieldStyles}
            />
          </Stack>
        </Stack>
      </FormGroup>
      <Box sx={boxStyles}>
        <Button sx={buttonStyles} variant="outlined" onClick={onBack}>
          {t('back')}
        </Button>
        <Button sx={buttonStyles} variant="contained" type="submit">
          {t('next')}
        </Button>
      </Box>
    </Box>
  );
};

import axios from 'axios';

import { type TRegisterCredentials } from '~/Shared/domain/user/components/RegisterCredentialsForm/schema';
import { type TBank } from '~/Employee/domain/user/components/Register/steps/BankDetails/schema';
import { type TDependent } from '~/Employee/domain/user/components/Register/steps/DependentDetails/schema';
import { type TPersonal } from '~/Employee/domain/user/components/Register/steps/PersonalDetails/schema';

type TRegister = TBank & TRegisterCredentials & TDependent & TPersonal;

export const registerEmployee = async (registerData: TRegister) => {
  const res = await axios.post<TRegister>('/auth/register/employee', {
    ...registerData,
  });

  return res.data;
};

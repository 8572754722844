import axios from 'axios';

export type TFeaturesKeys =
  | 'employerComplianceDocument'
  | 'visaService'
  | 'visaService'
  | 'tutorialMode'
  | 'testPage'
  | 'payAsYouGo';

export type TFeaturesFlag = {
  [key in TFeaturesKeys]: {
    enabled: boolean;
  };
};

export const getFeatureFlags = async () => {
  const response = await axios.get<TFeaturesFlag>(
    `${import.meta.env.VITE_APP_FEATURE_URL}?env=${
      import.meta.env.VITE_APP_ENVIROMENT
    }`
  );
  return response.data;
};

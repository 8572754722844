import { object, string, type TypeOf } from 'zod';

import { i18trans } from '~/translation/i18n';

const phoneSchema = object({
  number: string().min(
    1,
    i18trans.t('emergencyContactNumberRequired', { ns: 'user' })
  ),
  area: string(),
  code: string().min(1, i18trans.t('areaCodeRequired', { ns: 'user' })),
});

const emergencyContactSchema = object({
  person: string().min(
    1,
    i18trans.t('emergencyContactRequired', { ns: 'user' })
  ),
  relationshipType: string().min(
    1,
    i18trans.t('relationshipRequired', { ns: 'user' })
  ),
  phone: phoneSchema,
});

export const bankSchema = object({
  bank: object({
    name: string().min(1, i18trans.t('bankNameRequired', { ns: 'user' })),
    branchName: string().min(
      1,
      i18trans.t('branchNameRequired', { ns: 'user' })
    ),
    country: string().min(1, i18trans.t('countryRequired', { ns: 'user' })),
    accountName: string().min(
      1,
      i18trans.t('accountNameRequired', { ns: 'user' })
    ),
    accountNumber: string().min(
      1,
      i18trans.t('accountNumberRequired', { ns: 'user' })
    ),
    swift: string().min(1, i18trans.t('swiftRequired', { ns: 'user' })),
    emergencyContact: emergencyContactSchema,
  }),
});

export type TBank = TypeOf<typeof bankSchema>;

export const bankInit: TBank = {
  bank: {
    name: '',
    branchName: '',
    country: '',
    accountName: '',
    accountNumber: '',
    swift: '',
    emergencyContact: {
      person: '',
      relationshipType: '',
      phone: {
        number: '',
        area: '',
        code: '',
      },
    },
  },
};

import type { TStyles } from '~/Shared/types/TStyles';

export const task: TStyles = {
  padding: '12px 20px',
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: ({ palette }) => `1px solid ${palette.neutral[30]}`,
};

export const title: TStyles = {
  color: ({ palette }) => palette.neutral[100],
};

export const button: TStyles = {
  background: ({ palette }) => palette.blue[40],
  color: ({ palette }) => palette.common.white,
  minWidth: '80px',
  ':hover': {
    background: ({ palette }) => palette.blue[30],
  },
  ':disabled': {
    background: ({ palette }) => palette.neutral[60],
  },
};

export const buttonTitle: TStyles = {
  color: ({ palette }) => palette.neutral[10],
  fontSize: '11px',
  fontWeight: '600',
};

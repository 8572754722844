import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { resendCode } from '~/Shared/domain/user/api/auth/resendCode';
import { useNotification } from '~/Shared/hooks/useNotification';

export const useResendCode = () => {
  const { showNotification } = useNotification();
  const { t } = useTranslation('notification');

  return useMutation({
    mutationKey: ['resend-code'],
    mutationFn: resendCode,
    onSuccess: () => {
      showNotification('success', t('resendSuccess'));
    },
    onError: () => {
      showNotification('error', t('resendError'));
    },
  });
};

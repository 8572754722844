import axios, { AxiosError } from 'axios';

axios.interceptors.request.use(
  function (config) {
    config.baseURL = import.meta.env.VITE_APP_BASE_URL;
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (res) {
    return res;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

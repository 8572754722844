import { type TStyles } from '~/Shared/types/TStyles';

export const wrapperStyles: TStyles = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
};

export const boxTypoStyles: TStyles = {
  mt: '30px',
  mb: { xs: '20px', sm: '50px' },
  textAlign: 'center',
};

export const h1Styles: TStyles = {
  fontSize: ({ typography }) => typography.xxl.fontSize,
  lineHeight: ({ typography }) => typography.xxl.fontSize,
  color: ({ palette }) => palette.common.black,
  fontWeight: 700,
};

export const paragraphStyles: TStyles = {
  mt: { xs: '10px', sm: '16px' },
  maxWidth: { xs: 300, sm: 500 },
  fontSize: { xs: '12px', sm: '16px' },
  lineHeight: { xs: '18px', sm: '24px' },
};

export const spanStyles: TStyles = {
  fontSize: { xs: '12px', sm: '16px' },
  lineHeight: { xs: '18px', sm: '24px' },
  color: ({ palette }) => palette.common.black,
  textDecoration: 'underline',
};

export const buttonWrapStyles: TStyles = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  width: '100%',
  maxWidth: 240,
  justifyItems: 'center',
  alignItems: 'center',
  columnGap: '8px',
  rowGap: '10px',
};

export const buttonResendStyles: TStyles = {
  flex: 1,
  width: { xs: '120px', sm: '100%' },
  fontSize: { xs: '12px', sm: '16px' },
  lineHeight: { xs: '18px', sm: '24px' },
};

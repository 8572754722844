import * as Sentry from '@sentry/react';

import { useWhoAmI } from '~/Shared/domain/user/hooks/useWhoAmI';

export const useActiveWhoAmI = () => {
  const { data } = useWhoAmI();

  if (!data || data.type !== 'employer') {
    const errorMessage =
      'useActiveWhoami expected to receive a parameter, but none was provided';
    Sentry.captureException(errorMessage);
    throw new Error(errorMessage);
  }

  return data;
};

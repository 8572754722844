import React from 'react';
import { Navigate } from 'react-router-dom';

import { useFeatureFlags } from '~/Shared/config/featureFlags/hooks';
import type { TFeaturesKeys } from '~/Shared/config/featureFlags/api';
import { Loader } from '~/Shared/components/Loader';

interface IProps {
  flag?: TFeaturesKeys;
  children: React.ReactNode;
}

export const FeatureProvider = ({
  flag,
  children,
}: IProps): React.ReactElement => {
  const { isFeatureEnabled, isLoading } = useFeatureFlags();

  if (isLoading) {
    return <Loader />;
  }

  if (flag && !isFeatureEnabled(flag)) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

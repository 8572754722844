import { object, string, type TypeOf } from 'zod';

import { i18trans } from '~/translation/i18n';

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^_])[A-Za-z\d@$!%*?&#^_]{12,}$/;

export const registerCredentialsSchema = object({
  firstName: string()
    .min(1, i18trans.t('firstNameRequired', { ns: 'user' }))
    .max(50, i18trans.t('firstNameMaxCharacters', { ns: 'user' })),
  middleName: string().max(
    30,
    i18trans.t('middleNameMaxCharacters', { ns: 'user' })
  ),
  lastName: string()
    .min(1, i18trans.t('lastNameRequired', { ns: 'user' }))
    .max(30, i18trans.t('lastNameMaxCharacters', { ns: 'user' })),
  email: string().email(i18trans.t('emailInvalid', { ns: 'user' })),
  password: string().trim().regex(passwordRegex),
});

export type TRegisterCredentials = TypeOf<typeof registerCredentialsSchema>;

export const registerCredentialsInit: TRegisterCredentials = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  password: '',
};

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from './en/common.json';
import user from './en/user.json';
import contract from './en/contract.json';
import notification from './en/notification.json';
import invoices from './en/invoices.json';
import receipts from './en/receipts.json';
import settings from './en/settings.json';
import sidebar from './en/sidebar.json';
import jobs from './en/jobs.json';
import kyc from './en/kyc.json';
import table from './en/table.json';
import visa from './en/visa.json';
import tutorial from './en/tutorial.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'common';
    resources: {
      common: typeof common;
      contract: typeof contract;
      notification: typeof notification;
      user: typeof user;
      invoices: typeof invoices;
      receipts: typeof receipts;
      settings: typeof settings;
      sidebar: typeof sidebar;
      jobs: typeof jobs;
      kyc: typeof kyc;
      table: typeof table;
      visa: typeof visa;
      tutorial: typeof tutorial;
    };
  }
}

i18next.use(initReactI18next).init({
  returnNull: false,
  debug: false,
  fallbackLng: 'en',
  resources: {
    en: {
      common,
      contract,
      notification,
      user,
      invoices,
      receipts,
      settings,
      sidebar,
      jobs,
      kyc,
      table,
      visa,
      tutorial,
    },
  },
});

export const i18trans = i18next;

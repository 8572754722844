import axios from 'axios';

import { type IWhoAmIEmployerDTO } from '~/Shared/domain/user/api/user/dto/whoAmIEmployerDTO';
import { type IWhoAmIEmployeeDTO } from '~/Shared/domain/user/api/user/dto/whoAmIEmployeeDTO';
import { whoamiEmployerModel } from '~/Shared/domain/user/api/user/model/whoAmIEmployerModel';
import { whoamiEmployeeModel } from '~/Shared/domain/user/api/user/model/whoAmIEmployeeModel';

export const getWhoAmI = async () => {
  const res = await axios.get<IWhoAmIEmployerDTO | IWhoAmIEmployeeDTO>(
    '/user/whoami'
  );

  const userType = res.data.type;

  if (userType === 'employee') {
    return whoamiEmployeeModel(res.data);
  } else {
    return whoamiEmployerModel(res.data);
  }
};

import { Button, TextField } from '@mui/material';
import { useState } from 'react';

export const PageForTests = () => {
  const [value, setValue] = useState('');

  const handleClick = () => {
    throw new Error(value);
  };
  return (
    <div>
      <h1>Test Page</h1>
      <TextField value={value} onChange={(e) => setValue(e.target.value)} />
      <Button variant="contained" sx={{ mt: '40px' }} onClick={handleClick}>
        Click me
      </Button>
    </div>
  );
};

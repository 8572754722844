import { type TStyles } from '~/Shared/types/TStyles';

export const typographyStyles: TStyles = {
  color: ({ palette }) => palette.neutral[100],
  fontWeight: ({ typography }) => typography.fontWeightBold,
  marginBottom: '10px',
};

export const textFieldStyles: TStyles = {
  mt: 0,
};

export const stackStyles: TStyles = {
  mt: 2,
};

export const datePickerStyles: TStyles = {
  m: 0,
};

export const endAdornmentTypographyStyles: TStyles = {
  color: ({ palette }) => palette.neutral[60],
};

export const buttonBoxStyles: TStyles = {
  display: 'flex',
  width: '100%',
  justifyItems: 'center',
  alignItems: 'center',
  marginTop: '30px',
  columnGap: '8px',
};

export const buttonBackStyles: TStyles = { flex: 1 };

export const buttonSubmitStyles: TStyles = { flex: 1, letterSpacing: '1px' };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export const Copyright = () => {
  const { t } = useTranslation('common');
  return (
    <Typography variant="base">
      {t('copyright', { year: new Date().getFullYear() })}
    </Typography>
  );
};

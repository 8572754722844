import axios from 'axios';

import { type TOrganization } from '~/Employer/domain/user/components/Register/steps/Organization/schema';
import { type TRegisterCredentials } from '~/Shared/domain/user/components/RegisterCredentialsForm/schema';

type TRegister = TRegisterCredentials & { organization: TOrganization };

export const registerEmployer = async (registerData: TRegister) => {
  const res = await axios.post<TRegister>(
    '/auth/register/employer',
    registerData
  );

  return res.data;
};

import { createTheme } from '@mui/material';

import { neutral, red, green, yellow, blue } from './designSystem/colors';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    neutral: {
      ...neutral,
    },
    red: {
      ...red,
    },
    green: {
      ...green,
    },
    yellow: {
      ...yellow,
    },
    blue: {
      ...blue,
    },
  },
});

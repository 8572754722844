import React from 'react';
import { Stepper as MuiStepper, Step, StepLabel } from '@mui/material';

import { type TStyles } from '~/Shared/types/TStyles';

interface IStepper {
  steps: string[];
  activeStep: number;
  isTransparent?: boolean;
  sx?: TStyles;
}

export const Stepper = ({ steps, activeStep, isTransparent, sx }: IStepper) => {
  return (
    <MuiStepper
      orientation="horizontal"
      activeStep={activeStep}
      sx={sx}
      className={isTransparent ? 'transparent' : ''}
    >
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        return (
          <Step
            className={isTransparent ? 'transparent' : ''}
            key={index}
            {...stepProps}
          >
            <StepLabel className={isTransparent ? 'transparent' : ''}>
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </MuiStepper>
  );
};

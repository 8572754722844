import React, {
  useState,
  useEffect,
  type ReactElement,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import {
  boxImageStyles,
  boxStyles,
  container,
  copyrightWrapStyles,
  stepperStyles,
  stepsWrapStyles,
} from './index.style';

import { PersonalDetails } from '~/Employee/domain/user/components/Register/steps/PersonalDetails';
import { BankDetails } from '~/Employee/domain/user/components/Register/steps/BankDetails';
import { DependentDetails } from '~/Employee/domain/user/components/Register/steps/DependentDetails';
import {
  type TPersonal,
  personalInit,
} from '~/Employee/domain/user/components/Register/steps/PersonalDetails/schema';
import {
  type TBank,
  bankInit,
} from '~/Employee/domain/user/components/Register/steps/BankDetails/schema';
import {
  type TDependent,
  dependentInit,
} from '~/Employee/domain/user/components/Register/steps/DependentDetails/schema';
import { Stepper } from '~/Shared/components/Stepper';
import { useMultiStepForm } from '~/Shared/hooks/useMultiStepForm';
import Logo from '~/Shared/assets/logo-remote.svg';
import { sanitizeOutput } from '~/Shared/utils/sanitizeOutput';
import { useRegisterEmployee } from '~/Shared/domain/user/hooks/useRegisterEmployee';
import { Copyright } from '~/Shared/components/Copyright';

type TStep =
  | {
      label: string;
      component: ReactElement<{
        state: TPersonal;
        setState: Dispatch<SetStateAction<TPersonal>>;
        next: () => void;
      }>;
    }
  | {
      label: string;
      component: ReactElement<{
        state: TBank;
        setState: Dispatch<SetStateAction<TBank>>;
        back: () => void;
        next: () => void;
      }>;
    }
  | {
      label: string;
      component: ReactElement<{
        state: TDependent;
        setState: Dispatch<SetStateAction<TDependent>>;
        back: () => void;
        next: () => void;
        emailAddress: string;
      }>;
    };

export const Register = () => {
  const { t } = useTranslation('common');

  const { state: credentialsState } = useLocation();
  const navigate = useNavigate();
  const { currentStep, next, back } = useMultiStepForm();
  const [personalState, setPersonalState] = useState<TPersonal>(personalInit);
  const [bankState, setBankState] = useState<TBank>(bankInit);
  const [dependentState, setDependentState] =
    useState<TDependent>(dependentInit);

  const { mutateAsync } = useRegisterEmployee(credentialsState.email);

  const checkIfCredentialsExist = () => {
    if (!credentialsState) {
      navigate('/register');
    }
  };

  useEffect(() => {
    checkIfCredentialsExist();
  }, []);

  const handleSubmit = async (lastStepState: TDependent) => {
    const mergedState = {
      ...credentialsState,
      ...personalState,
      ...bankState,
      ...lastStepState,
    };

    sanitizeOutput(mergedState);
    await mutateAsync(mergedState);
  };

  const steps: TStep[] = [
    {
      label: t('personalDetails'),
      component: (
        <PersonalDetails
          state={personalState}
          setState={setPersonalState}
          next={next}
        />
      ),
    },
    {
      label: t('bankDetails'),
      component: (
        <BankDetails
          state={bankState}
          setState={setBankState}
          back={back}
          next={next}
        />
      ),
    },
    {
      label: t('dependentDetails'),
      component: (
        <DependentDetails
          state={dependentState}
          setState={setDependentState}
          back={back}
          handleSubmitForm={handleSubmit}
        />
      ),
    },
  ];

  return (
    <Box>
      <Box sx={boxStyles}>
        <Box sx={boxImageStyles}>
          <img draggable="false" src={Logo} />
        </Box>
        <Stepper
          activeStep={currentStep}
          steps={steps.map((step) => step.label)}
          isTransparent={true}
          sx={stepperStyles}
        />
      </Box>
      <Box sx={container}>
        <Box sx={stepsWrapStyles}>
          {currentStep < steps.length ? steps[currentStep]?.component : null}
        </Box>
      </Box>
      <Box sx={copyrightWrapStyles}>
        <Copyright />
      </Box>
    </Box>
  );
};

import { type TStyles } from '~/Shared/types/TStyles';

export const boxStyles: TStyles = {
  mt: '10px',
};

export const typographyStyles: TStyles = {
  color: ({ palette }) => palette.neutral[100],
  fontWeight: ({ typography }) => typography.fontWeightMedium,
};

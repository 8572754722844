import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { useVerifyAccount } from '~/Shared/domain/user/api/auth/verifyAccount';
import { Loader } from '~/Shared/components/Loader';
import { InfoPage } from '~/Shared/pages/InfoPage';

export const VerifyAccountPage = () => {
  const { t } = useTranslation(['common', 'user']);
  const navigate = useNavigate();
  const { code } = useParams();

  const { isLoading, isSuccess } = useVerifyAccount(code);

  if (isLoading) {
    return <Loader height="100vh" />;
  }

  if (isSuccess) {
    return (
      <InfoPage type="info" message={t('user:activationSuccessful')}>
        <Button variant="contained" onClick={() => navigate('/login')}>
          {t('user:signIn')}
        </Button>
      </InfoPage>
    );
  }

  return (
    <InfoPage type="error" message={t('user:invalidCode')}>
      <Button variant="contained" onClick={() => navigate('/app')}>
        {t('goBack')}
      </Button>
    </InfoPage>
  );
};

import { Drawer, useTheme } from '@mui/material';

import { Content } from './Content';

interface DesktopVariantInterface {
  width: string;
}

export const DesktopVariant = ({ width }: DesktopVariantInterface) => {
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          boxSizing: 'border-box',
          width: width,
          borderBottom: `1px solid ${theme.palette.neutral[40]}`,
        },
      }}
      sx={{
        display: { xs: 'none', md: 'block' },
      }}
      open
    >
      <Content />
    </Drawer>
  );
};

import React from 'react';
import { Typography, type SxProps } from '@mui/material';

interface IHelperText {
  text: string | undefined;
  sx?: SxProps;
}

export const HelperText: React.FC<IHelperText> = ({ text, sx }) => {
  return text ? (
    <Typography sx={{ mb: 1.25, fontSize: '13px', lineHeight: '24px', ...sx }}>
      {text}
    </Typography>
  ) : null;
};

import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet as RouterOutlet } from 'react-router-dom';

interface OutletInterface {
  mobileHeight: string;
  desktopHeight: string;
}

export const Outlet = ({ mobileHeight, desktopHeight }: OutletInterface) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginTop: {
          xs: mobileHeight,
          md: desktopHeight,
        },
        background: theme.palette.neutral[30],
        minHeight: {
          xs: `calc(100vh - ${mobileHeight})`,
          md: `calc(100vh - ${desktopHeight})`,
        },
      }}
    >
      <RouterOutlet />
    </Box>
  );
};

import React from 'react';
import { Box } from '@mui/material';

import { useMediaQuery } from '~/Shared/hooks/useMediaQuery';
import { Copyright } from '~/Shared/components/Copyright';
import Logo from '~/Shared/assets/logo-remote.svg';

interface IProps {
  children: React.ReactNode;
  background: string;
}

export const SplitOverlay: React.FC<IProps> = ({ background, children }) => {
  const { isBigScreen, md } = useMediaQuery();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        [md]: {
          flexDirection: 'column',
          width: '100vw',
          height: 'auto',
          alignItems: 'center',
        },
      }}
    >
      <img
        draggable="false"
        src={background}
        style={{
          maxHeight: isBigScreen ? '100%' : 'auto',
          maxWidth: isBigScreen ? 'auto' : '100%',
          padding: '16px',
          [md]: {
            height: '50%',
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          maxWidth: '582px',
          width: '100%',
          margin: '16px 50px 16px 70px',
          [md]: {
            margin: '0',
            padding: '16px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              mb: '50px',
              [md]: {
                display: 'none',
              },
            }}
          >
            <img draggable="false" src={Logo} />
          </Box>
          <Box>{children}</Box>
        </Box>

        <Box
          sx={{
            bottom: 20,
            [md]: {
              display: 'none',
            },
          }}
        >
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
};

import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';

import { registerEmployee } from '~/Shared/domain/user/api/auth/registerEmployee';
import { useNotification } from '~/Shared/hooks/useNotification';

export const useRegisterEmployee = (emailAddress: string) => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();

  return useMutation({
    mutationKey: ['register-employee'],
    mutationFn: registerEmployee,
    onSuccess: () => {
      navigate('/register/success', { state: emailAddress });
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        showNotification('error', error?.response?.data.error);
        return;
      }
    },
  });
};

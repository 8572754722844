import axios from 'axios';

import type {
  ITutorialDTO,
  TTutorialSteps,
} from '~/Employer/domain/tutorial/api/dto/tutorial';
import { createTutorialModel } from '~/Employer/domain/tutorial/api/model/tutorial';

export const getTutorial = async () => {
  const response = await axios.get<ITutorialDTO>('/employers/tutorial');

  return createTutorialModel(response.data);
};

export const updateTutorial = async (step: TTutorialSteps) => {
  const response = await axios.put<ITutorialDTO>(`/employers/tutorial/${step}`);
  return response;
};

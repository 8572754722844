import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import SnippetFolderOutlinedIcon from '@mui/icons-material/SnippetFolderOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import HailIcon from '@mui/icons-material/Hail';
import { useTranslation } from 'react-i18next';

import { useFeatureFlags } from '~/Shared/config/featureFlags/hooks';
import type { TFeaturesKeys } from '~/Shared/config/featureFlags/api';

export interface Route {
  hidden?: boolean;
  text: string;
  icon: JSX.Element;
  url: string;
  featureFlagName?: TFeaturesKeys;
  links: {
    text: string;
    url: string;
    icon: JSX.Element;
  }[];
}

export const useMenu = () => {
  const { t } = useTranslation(['sidebar']);
  const { featureFlags } = useFeatureFlags();

  const routes: Route[] = [
    {
      text: t('sidebar:contract'),
      icon: <LibraryBooksOutlinedIcon />,
      url: 'contract',
      links: [
        {
          text: t('sidebar:createNewContract'),
          url: '/employer/contract/new',
          icon: <PlaylistAddIcon />,
        },
        {
          text: t('sidebar:contractList'),
          url: '/employer/contract/list',
          icon: <DescriptionOutlinedIcon />,
        },
      ],
    },
    {
      text: t('sidebar:invoices'),
      icon: <RequestQuoteOutlinedIcon />,
      url: 'invoices',
      links: [
        {
          text: t('sidebar:invoicesList'),
          url: '/employer/invoices/list',
          icon: <ReceiptOutlinedIcon />,
        },
      ],
    },
    {
      text: t('sidebar:receipts'),
      icon: <ReceiptLongIcon />,
      url: 'receipts',
      links: [
        {
          text: t('sidebar:receiptsList'),
          url: '/employer/receipts/list',
          icon: <AssignmentOutlinedIcon />,
        },
      ],
    },
    {
      text: t('sidebar:tracker'),
      icon: <TrackChangesIcon />,
      url: 'tracker',
      links: [
        {
          text: t('sidebar:immigration'),
          url: '/employer/tracker-immigration',
          icon: <HailIcon />,
        },
      ],
    },
    {
      text: t('sidebar:recruitment'),
      icon: <WorkOutlineOutlinedIcon />,
      url: 'joblisting',
      links: [
        {
          text: t('sidebar:postNewJob'),
          url: '/employer/joblisting/new',
          icon: <PostAddOutlinedIcon />,
        },
        {
          text: t('sidebar:jobList'),
          url: '/employer/joblisting/list',
          icon: <ListAltOutlinedIcon />,
        },
      ],
    },
    {
      text: t('sidebar:kyc'),
      icon: <SnippetFolderOutlinedIcon />,
      url: 'kyc',
      featureFlagName: 'employerComplianceDocument',
      links: [
        {
          text: t('sidebar:documentList'),
          url: '/employer/kyc/documents',
          icon: <TextSnippetOutlinedIcon />,
        },
      ],
    },
    {
      text: t('sidebar:settings'),
      icon: <SettingsOutlinedIcon />,
      url: 'settings',
      links: [
        {
          text: t('sidebar:settings'),
          url: '/employer/settings',
          icon: <SettingsSuggestOutlinedIcon />,
        },
      ],
    },
    {
      text: t('sidebar:teamName'),
      icon: <PeopleAltOutlinedIcon />,
      url: 'contract2',
      links: [],
      hidden: true,
    },
  ];

  const modifiedRoutes = routes.map((route) => {
    const isNotEnabled =
      route.featureFlagName &&
      featureFlags &&
      !featureFlags[route.featureFlagName]?.enabled;

    if (isNotEnabled) {
      return {
        ...route,
        hidden: true,
      };
    }

    return route;
  });

  return {
    menu: modifiedRoutes,
  };
};

import type { FC, PropsWithChildren } from 'react';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import { styled } from '@mui/material';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme: { palette, typography } }) => ({
    '&.notistack-MuiContent': {
      borderRadius: '12px',
      boxShadow: 'none',
      color: palette.neutral[10],
      fontFamily: typography.fontFamily,
      fontSize: '16px',
      fontWeight: typography.fontWeightRegular,
      padding: '20px 25px',
      width: '400px',

      '& #notistack-snackbar': {
        gap: '10px',
        padding: 0,

        '.MuiSvgIcon-root': {
          height: '40px',
          width: '40px',
        },
      },
    },

    '&.notistack-MuiContent-success': {
      backgroundColor: palette.green[80],
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: palette.yellow[70],
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: palette.red[50],
    },
  })
);

export const StyledSnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={2}
      Components={{
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
      iconVariant={{
        success: <CheckCircleIcon />,
        warning: <ErrorIcon />,
        error: <CancelIcon />,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
